.tasks-v2 {
  .tasks-table-v2 {
    .circular-icon {
      font-size: 16px;
      cursor: pointer;
    }
  }

  .task-closed {
    text-decoration: line-through;
  }

  .timeline.tasks-chain {
    li {
      margin-top: 15px;
    }
  }

  .tasks-chain {
    li.header > *:not(.breeder-id) {
      height: 60px;
      width: 60px;
      margin-left: 3px;
      border: 3px solid var(--border-light-grey);
    }

    li.header {
      margin-bottom: 30px;
      height: 85px;
    }
  }

  .row-horizon.schedule {
    margin-left: 75px;
    overflow-x: auto;

    .breeder-id {
      width: 100px;
      height: 40px;
      padding: 5px 20px;
      border-radius: 10px;
      background-color: #edf0f2;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: -16px;
      margin-top: 10px;
      cursor: pointer;

      .title {    
      font-size: 11px !important;
      font-weight: bold !important;
      line-height: 10px !important;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      }

      .description {
        color: #888a8c;
        font-size: 13px;
        font-weight: bold;
      }
    }
  }

  .date-lines {
    top: 105px;

    .date-line {
      hr {
        margin-top: 2px;
        margin-left: 0px;
        width: 100%;
      }

      .date {
        color: #888;
        font-size: 10px;
        font-weight: 600;
        margin-left: 2px;
        margin-top: -7px;
        width: 40px;
        font-size: 14px;
        font-weight: 500;
        color: #888a8c;
        float: unset;
      }
    }
  }

  .timeline::before {
    background: #edf0f2;
  }

  .history-icon {
    margin-left: 10px;
    font-size: 20px;
  }

  .butchered-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1.5px solid var(--primary-white);
    padding: 0px;
  }

  .select-icon-of-task .icon-circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: grey;
    color: #fff;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 14px;
    color: var(--primary-white);
    padding-top: 0px;
    cursor: pointer;
  }
}

.tasks-menu-v2 {
  .dropdown-menu {
    border: none !important;
    border-radius: 4px !important;
    box-shadow: 0px 0px 2px #888;

    .header.flex-center {
      height: 80px;
      justify-content: center;
      flex-direction: column;
      background-color: #0f1226 !important;
      color: var(--primary-white) !important;

      .task-count {
        color: var(--secondary-green);
      }
      .main-text {
        font-size: 18px;
        font-weight: 500;
      }
      .sub-text {
        font-size: 12px;
        font-weight: normal;
      }
    }

    .menu {
      max-height: 300px !important;

      .main-content {
        width: 100%;
        color: #2d2d2d !important;
        display: flex !important;
        align-items: center;
        padding: 15px !important;

        div:first-child {
          width: 100%;
        }
      }
      .fa.circle-background {
        font-size: 18px;
        width: 30px;
        height: 30px;
        line-height: 30px;
        margin-right: 8px;
      }
      .fa.notification-read {
        font-size: 14px;
        margin: 0 !important;
      }
    }

    .view-all.text-center {
      border-top: 1px solid #f4f4f4;

      a:first-child {
        padding: 20px;
        color: #0aa7d0;
      }
    }
  }
}

@media (min-width: 768px) {
  .tasks-menu-v2 .dropdown-menu {
    width: 450px !important;
  }
}
