.litters-v2 .main-filters .select2 {
  width: 100% !important;
}
.litters-v2 .bg-olive {
  background-color: var(--secondary-green) !important;
}
.litters-v2 .widget-user .box-footer {
  padding-top: 40px;
}
.litters-v2 .litter-list-image img {
  margin-right: 5px;
  border: 2px solid;
}
.litters-v2 .layout-switch-button.profile-actions {
  display: inline-flex;
  margin-bottom: 0px;
}
.litters-v2 .layout-switch-button.profile-actions .actions {
  margin-right: 20px;
}
.litters-v2 .breedlist-header-actions .fa.fa-sort-amount-asc {
  font-size: 16px;
}
.litters-v2 .description-header {
  font-size: 24px;
  white-space: nowrap;
}
.litters-v2 .description-header .unit {
  font-size: 17px;
}
.litters-v2 #litter-weight-modal .info-box,
.litters-v2 #global-litter-weight-modal .info-box,
.litters-v2 #cage-weight-modal .info-box,
.litters-v2 #global-cage-weight-modal .info-box,
.litters-v2 #cage-sell-modal .info-box,
.litters-v2 #litter-sell-modal .info-box {
  border-radius: 4px;
}
.litters-v2 #litter-weight-modal .info-box .info-box-number,
.litters-v2 #global-litter-weight-modal .info-box .info-box-number,
.litters-v2 #cage-weight-modal .info-box .info-box-number,
.litters-v2 #global-cage-weight-modal .info-box .info-box-number,
.litters-v2 #cage-sell-modal .info-box .info-box-number,
.litters-v2 #litter-sell-modal .info-box .info-box-number {
  font-size: 16px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
}
.litters-v2 #litter-weight-modal .info-box .info-box-icon img,
.litters-v2 #global-litter-weight-modal .info-box .info-box-icon img,
.litters-v2 #cage-weight-modal .info-box .info-box-icon img,
.litters-v2 #global-cage-weight-modal .info-box .info-box-icon img,
.litters-v2 #cage-sell-modal .info-box .info-box-icon img,
.litters-v2 #litter-sell-modal .info-box .info-box-icon img {
  max-width: 70%;
  margin: 15px auto;
  border: 3px solid;
}
.litters-v2 #litter-weight-modal .info-box .info-box-content,
.litters-v2 #global-litter-weight-modal .info-box .info-box-content,
.litters-v2 #cage-weight-modal .info-box .info-box-content,
.litters-v2 #global-cage-weight-modal .info-box .info-box-content,
.litters-v2 #cage-sell-modal .info-box .info-box-content,
.litters-v2 #litter-sell-modal .info-box .info-box-content {
  min-height: 90px;
  color: black;
  padding: 5px 15px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.litters-v2 #litter-weight-modal .info-box.bg-maroon .info-box-content,
.litters-v2 #global-litter-weight-modal .info-box.bg-maroon .info-box-content,
.litters-v2 #cage-weight-modal .info-box.bg-maroon .info-box-content,
.litters-v2 #global-cage-weight-modal .info-box.bg-maroon .info-box-content,
.litters-v2 #cage-sell-modal .info-box.bg-maroon .info-box-content,
.litters-v2 #litter-sell-modal .info-box.bg-maroon .info-box-content {
  background-color: #fae4ec;
  border: 1px solid #d81a61;
}
.litters-v2 #litter-weight-modal .info-box.bg-aqua .info-box-content,
.litters-v2 #global-litter-weight-modal .info-box.bg-aqua .info-box-content,
.litters-v2 #cage-weight-modal .info-box.bg-aqua .info-box-content,
.litters-v2 #global-cage-weight-modal .info-box.bg-aqua .info-box-content,
.litters-v2 #cage-sell-modal .info-box.bg-aqua .info-box-content,
.litters-v2 #litter-sell-modal .info-box.bg-aqua .info-box-content {
  background-color: #e5faff;
  border: 1px solid #0aa7d0;
}
.litters-v2 .litter-butcher-table td {
  border-color: transparent !important;
}
.litters-v2 .transfer-field {
  justify-content: center;
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .litters-v2 .description-text {
    font-size: 10px !important;
  }
  .litters-v2 .description-header {
    font-size: 17px !important;
  }
  .litters-v2 .description-header .unit {
    font-size: 12px !important;
  }
  .litters-v2 .litter.widget-user-image {
    margin-left: -45px;
    top: 80px;
  }
  .litters-v2 .litter.widget-user-image > img {
    width: 80px !important;
  }
  .litters-v2 .widget-user .box-footer .row {
    margin: 0 -10px;
  }
  .litters-v2 .widget-user .box-footer .row > div {
    padding: 0 5px !important;
  }
  .litters-v2 .tile-breeder-content {
    width: 75%;
  }
}
