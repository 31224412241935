.accounts-v2 {
  .subscriptions {
    .header {
      text-align: center;

      .main-title {
        font-weight: 700;
        font-size: 42px;
      }

      .plan-status {
        font-size: 22px;
      }

      .main-description {
        font-size: 16px;
      }
    }

    .header.current-plan-header {
      text-align: left;
    }

    .current-plan-container {
      padding: 0px 0px 70px 0px;
      border-radius: 9px;
      background-color: #e9f3e5;

      .current-plan-pricing {
        margin: 30px 20px;
        padding-bottom: 20px;
        padding-top: 20px;
        border-bottom: 1px solid var(--primary-green);

        .price {
          border-radius: 6px;
          background: black;
          color: white;
          font-weight: bold;
          padding: 20px 40px;
          font-size: 35px;
          margin-right: 20px;
        }

        .description {
          font-size: 20px;
          font-weight: bold;
        }

        .discount {
          margin-left: auto;
          border: 2px dashed var(--primary-green);
          padding: 20px;
          border-radius: 8px;
          background: #fffef7;
          max-width: 280px;

          .title {
            font-size: 14px;
            font-weight: 600;
            float: left;
            width: 40%;
          }

          .description {
            font-size: 19px;
            font-weight: bold;
            text-transform: uppercase;
            color: var(--primary-green);
            float: left;
            width: 60%;
          }
        }
      }

      .current-plan-details > * {
        padding: 0 40px;
      }
      .current-plan-details {
        padding: 0;

        .icon {
          padding: 4px;
          background-color: var(--primary-green);
          border-radius: 50%;
          width: 45px;
          height: 45px;
          margin-right: 15px;

          img {
            width: 36px;
            padding-top: 6px;
          }
        }

        .title {
          font-size: 20px;
          font-weight: bold;
        }

        .description {
          font-size: 17px;
        }

        .plan-content {
          .main {
            font-size: 19px;
          }

          .description {
            font-size: 15px;
            color: var(--primary-green);
          }
        }

        .amount {
          font-size: 19px;
          font-weight: bold;

          .discount {
            font-size: 14px;
            display: inline;
            font-weight: 500;
            margin-left: 10px;
          }
        }

        .expiry {
          font-size: 15px;
        }

        .btn {
          border-radius: 100px;
          padding: 6px 30px;
          font-weight: 500;
        }

        .middle-content {
          background-color: #d5e4d0;
          margin: 30px 0px;
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }

    .cancel-container {
      padding: 20px 40px;

      .btn {
        float: right;
        border-radius: 100px;
        padding: 6px 30px;
        font-weight: 500;
      }
    }

    .plan-box {
      min-height: 700px;
      position: relative;
      border: 1px solid #e7e7e7;
      box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.1);

      .most-popular {
        background: linear-gradient(180deg, #dd4b39 0%, #bc4031 100%);
        box-shadow: inset 0px -1px 5px rgba(0, 0, 0, 0.221774);
        position: absolute;
        top: -30px;
        padding: 5px;
        width: 100%;
        text-align: center;
        color: var(--primary-white);
        font-weight: bold;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
      }

      .header {
        height: 220px;
        padding: 40px;
        position: relative;
        color: var(--primary-white);
        background: linear-gradient(
          360deg,
          rgba(0, 99, 161, 0.7) 0%,
          rgba(0, 166, 226, 0.7) 100%
        );

        .plan-type {
          font-size: 19px;
          font-weight: 500;
        }
        .plan-amount {
          font-size: 40px;
          font-weight: bolder;
        }
        .plan-duration {
          font-size: 12px;
          font-weight: bold;
        }
        .plan-description {
          font-size: 11px;
        }
      }

      .header::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        background-repeat: repeat;
        height: 10px;
        background-size: 20px 20px;
        background-image: radial-gradient(
          circle at 10px -5px,
          transparent 12px,
          var(--primary-white) 13px
        );
      }

      .header::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        background-repeat: repeat;
        height: 15px;
        background-size: 40px 20px;
        background-image: radial-gradient(
          circle at 10px 15px,
          var(--primary-white) 12px,
          transparent 13px
        );
      }

      .body {
        padding: 20px 20%;

        .plan-feature {
          font-size: 16px;
          margin-top: 10px;

          .main {
            font-weight: bold;
          }

          .text {
            margin-left: 15px;
          }

          .fa {
            color: var(--primary-green);
            font-size: 22px;
          }
        }
      }

      .action-container {
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;

        .upgrade-btn {
          border-radius: 100px;
          text-align: center;
          padding: 6px;
          font-weight: bold;
          width: 80%;
        }
      }

      .current-plan-v2 {
        background-color: var(--primary-white);
        color: var(--primary-green);
        border-width: 2px;
      }
    }

    .plan-preview {
      border: 2px solid black;
      height: 200px;
      width: 400px;
      border-radius: 10px;
      padding: 20px;
      margin: 0px 10px;

      .title {
        font-weight: bold;
        margin-bottom: 30px;
      }

      .entry {
        display: flex;

        .value {
          margin-left: auto;
        }
      }
    }

    .plan-preview.discount {
      width: 200px;
      height: auto;
    }

    .plan-preview.old-plan {
      opacity: 0.5;
      border-color: #878585;
    }

    .back-to-plans {
      background-color: transparent;
      display: flex;
      font-weight: 500;
      float: left;
      position: absolute;

      i {
        font-size: 21px;
        margin-right: 10px;
      }
    }
  }

  .payment-details {
    margin: 50px 70px;

    .title {
      font-weight: 700;
      font-size: 18px;
    }

    .btn.btn-success {
      border-radius: 100px;
      padding: 6px 30px;
      font-weight: 500;
    }

    .terms-and-conditions {
      font-size: 12px;

      a {
        color: var(--primary-green);
        font-weight: 500;
      }
    }
  }

  .plan-duration-container {
    .plan-duration-list {
      padding: 0;

      .tab {
        margin-top: 1px;
        margin-bottom: 0;
        margin-left: 1px;
        margin-right: 0;
        display: inline-block;
        vertical-align: bottom;
        list-style: none;

        a {
          padding: 10px 25px;
          display: block;
          border: solid transparent;
          position: relative;
          font-size: 16px;
          line-height: 18px;
          text-transform: uppercase;
          -webkit-box-shadow: none;
          box-shadow: none;
          border-radius: 100px;
          background: transparent;
          color: var(--primary-green);
          font-weight: 500;
          border: 2px solid var(--primary-green);
          opacity: 0.5;
        }
      }

      .tab.active {
        a {
          background: var(--primary-green);
          color: var(--primary-white);
          opacity: 1;
        }
      }

      .tab.toggle {
        a {
          background: none;
          color: var(--primary-green);
          border: none;
          font-size: 40px;
          padding: 2px 5px;
          opacity: 1;
        }
      }
    }
  }

  .alert-v2 {
    .title {
      font-size: 18px;
      font-weight: 500;
    }

    .description {
      font-size: 14px;
      font-weight: 400;
    }
  }

  @media (max-width: 750px) {
    .current-plan-pricing {
      flex-direction: column;

      .discount {
        margin-right: auto;
      }
    }
  }
}
