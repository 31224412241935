.settings-v2 .box-header.breedlist-header {
  padding: 0 15px;
}
.settings-v2 .modal-nav:not(.border-btm) {
  border-bottom: 3px transparent solid;
}
.settings-v2 .box.box-solid.box-primary.settings {
  border-radius: 10px;
  border-color: transparent;
}
.settings-v2 .box.box-solid.box-primary.settings .box-header {
  background-color: var(--gunmetal) !important;
  color: var(--primary-white);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 10px 20px;
}
.settings-v2 .box.box-solid.box-primary.settings .box-header .title {
  font-size: 18px;
  font-weight: bold;
}
.settings-v2 .box.box-solid.box-primary.settings .box-footer {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 30px;
}
.settings-v2 .box.box-solid.box-primary.settings .box-footer button.btn {
  margin: 0px 5px;
  padding: 8px 20px;
  font-size: 12px;
  min-width: 90px;
}
.settings-v2 .box.box-solid.box-primary.settings button.btn:not(.table-btn) {
  border-radius: 100px;
  font-weight: 550;
}
.settings-v2 .box.box-solid.box-primary.settings button.btn:not(.table-btn) .fa {
  font-size: 18px;
  width: 20px;
}
.settings-v2 .box.box-solid.box-primary.settings .form-group button.btn:not(:first-child) {
  margin-left: 5px;
}
.settings-v2 .box.box-solid.box-primary.settings button.btn.btn-custom-action .fa {
  color: var(--secondary-grey);
  font-size: 6px;
  width: auto;
}
.settings-v2 .box.box-solid.box-primary.settings .timeline > li {
  margin-bottom: 0;
}
.settings-v2 .box.box-solid.box-primary.settings .timeline-inverse > li > .timeline-item {
  background: none;
  border: none;
  box-shadow: none;
}
.settings-v2 .box.box-solid.box-primary.settings .timeline-inverse > li > .timeline-item .timeline-header {
  border-bottom: none;
}
.settings-v2 .box.box-solid.box-primary.settings .timeline-inverse > li > .timeline-item .timeline-header > * {
  margin: 0 5px;
}
.settings-v2 .box.box-solid.box-primary.settings .timeline-inverse > li > .timeline-item .timeline-header .time {
  width: 60px;
}
.settings-v2 .box.box-solid.box-primary.settings .timeline-inverse > li > .timeline-item .timeline-header .title {
  min-width: 70px;
}
.settings-v2 .box.box-solid.box-primary.settings .timeline > li > .fa,
.settings-v2 .box.box-solid.box-primary.settings .timeline > li > .glyphicon,
.settings-v2 .box.box-solid.box-primary.settings .timeline > li > .ion {
  top: 13px;
}
.settings-v2 .box.box-solid.box-primary.settings .timeline:before {
  top: 13px;
  height: 100%;
}
.settings-v2 .box.box-solid.box-primary.settings .timeline-header span {
  font-size: 14px;
}
.settings-v2 .sub-label {
  font-size: 12px;
  color: var(--secondary-grey);
}
.settings-v2 .rabbitry-settings-warning {
  min-height: 80px;
  width: 100%;
  margin: 30px 0;
  border-radius: 6px;
  background-color: #f4f4f4;
  padding: 30px;
}
.settings-v2 .rabbitry-settings-warning .title {
  font-size: 20px;
  font-weight: bold;
}
.settings-v2 .rabbitry-settings-warning .title img {
  margin-right: 20px;
}
.settings-v2 .rabbitry-settings-warning .description {
  font-size: 20px;
  margin-left: 10px;
}
.settings-v2 .rabbitry-settings-warning-sm {
  min-height: 40px;
  margin: 10px 0;
  padding: 10px 30px;
}
.settings-v2 .rabbitry-settings-warning-sm .title {
  font-size: 14px;
}
.settings-v2 .rabbitry-settings-warning-sm .title img {
  margin-right: 20px;
}
.settings-v2 .rabbitry-settings-warning-sm .description {
  font-size: 14px;
}
.settings-v2 .settings-image-upload .image-container {
  justify-content: center;
}
.settings-v2 .settings-image-upload .image-container .image {
  position: relative;
}
.settings-v2 .settings-image-upload .image-container .image img {
  width: 85px;
  height: 85px;
  object-fit: cover;
  display: block;
  margin-top: 0 !important;
  float: left;
  cursor: default;
}
.settings-v2 .settings-image-upload .image-container .image .fa {
  position: absolute;
  right: -15px;
  top: 25px;
  background-color: var(--primary-green);
  color: white;
  font-size: 18px;
  padding: 0.5em;
  -webkit-text-stroke: revert;
  cursor: pointer;
}
.settings-v2 .treatment-tasks-container {
  background-color: #f3f3f3;
  border-radius: 14px;
  padding: 20px;
}
@media (max-width: 768px) {
  .settings-v2 .box-header.breedlist-header {
    padding-top: 25px;
  }
}
