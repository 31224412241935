.breeders-v2 {
  font-family: "Roboto", sans-serif !important;

  .table-responsive {
    border: none !important;
  }

  .breeder.widget-user-image > img {
    width: 85px;
  }

  .breeder.widget-user-image {
    margin-left: -45px;
    top: 85px;
    z-index: 10;
  }

  .btn-custom-action {
    background-color: transparent;
    border: none;
  }

  .tile-view .btn-custom-action .fa {
    color: var(--primary-white);
    font-size: 5px;
  }

  .widget-user:not(.pedigree-v2) {
    margin-bottom: 30px;

    .widget-user-header {
      height: 125px;
    }

    .widget-user-header.transfer-header {
      position: relative;
    }

    .widget-user-username {
      font-size: 22px;
      font-weight: bold;
      text-overflow: ellipsis;
    }

    .widget-user-desc {
      font-size: 14px;
      font-weight: 500;
      margin: 1px 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .widget-user .box-footer:not(.revert-pt) {
    padding-top: 10px;
  }

  .color-doe .description-header {
    color: #d81a61;
  }
  .color-buck .description-header {
    color: #0090b9;
  }

  .description-header {
    font-size: 30px;
    font-weight: bold;
  }
  .description-header .unit {
    font-size: 18px;
  }
  .description-header .unit.align-top {
    vertical-align: top;
  }
  .description-header .unit.align-baseline {
    vertical-align: baseline;
  }
  .description-text {
    font-size: 11px;
    font-weight: bold;
    color: #333333;
  }

  .content-header > .box-header {
    padding: 15px;
    background-color: var(--primary-white);
  }
  .box-header > .btn {
    margin: 0 3px;
  }

  .breedlist-content.list-view {
    background: var(--primary-white);
    padding: 40px 20px;
    border-radius: 8px;
  }

  .list-view.table-container {
    margin: 0 !important;
  }
  .list-view.table-container {
    margin: 0 !important;
  }
  .list-view.table-container .table-header > div {
    display: inline;
  }

  .content-header {
    padding: 0 !important;
  }
  .content-header a.btn {
    color: #465156;
  }
  .content-header .btn span {
    font-size: 15px;
    font-weight: 500;
  }
  .content-header .btn .fa {
    font-size: 18px;
  }
  .content-header .header-title {
    font-size: 16px;
    font-weight: bold;
    vertical-align: middle;
  }

  .layout-switch-button {
    margin-left: auto;
  }
  .layout-switch-button .btn .header-title {
    margin-left: 12px;
  }
  .layout-switch-button .btn .fa {
    font-size: 23px;
    color: #465156;
  }
  .layout-switch-button .btn {
    padding-left: 0;
    padding-right: 0;
  }

  .rounded-circle {
    border-radius: 50%;
  }
  
  .cage-card-submenu-dropdown {
    border-color: transparent;
  } 
  
  .cage-card-submenu-dropdown .fa {
    font-size: 14px !important;
  } 

  .breedlist-table {
    .circular-icon {
      color: white;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      font-size: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .circular-icon.fa-venus {
      background-color: #d81a61;
    }
    .circular-icon.fa-mars {
      background-color: #0092bb;
    }
  }

  .table-v2 td {
    font-size: 14px;
  }

  .table-v2 td a.breeder-category {
    color: #03985d;
    text-decoration: underline;
  }

  .pagination .active a {
    color: var(--primary-white);
    background-color: #03985d;
  }

  .table-header {
    margin-bottom: 25px;
  }
  .table-title {
    margin-right: 30px;
    font-size: 20px;
    font-weight: 900;
  }
  .btn.breeder-menu {
    background-color: var(--primary-white);
    padding: 9px 15px;
    font-size: 14px;
    font-weight: 500;
    color: #222222;
    border-radius: 8px;
    margin: 0px 2px;
  }
  .btn.breeder-menu.active {
    background-color: #465156;
    color: var(--primary-white);
  }
  .table-actions .btn.breeder-menu {
    border-radius: 4px;
    padding: 9px 7px;
    min-width: 39.5px;
    justify-content: center;
  }
  .table-actions .dropdown-menu {
    min-width: 50px;
    right: 0;
    left: auto;
    border-radius: 2px;
    box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.24),
      0 0 16px 0 rgba(0, 0, 0, 0.12);
  }

  .display-actions.dropdown-menu {
    min-width: 200px;
  }
  .display-actions .icheck-group {
    padding: 0px 12px;
  }
  .display-actions .icheck-label {
    padding-left: 25px;
    font-size: 16px;
    font-weight: normal;
    color: #777;
  }

  .main-table-filters {
    padding: 10px 0px;

    .filter-actions {
      padding-top: 15px;
      margin-top: 10px;
      text-align: right;
      border-top: 1px solid #c5c5c5;

      .btn {
        padding: 8px 38px;
        border-radius: 100px;
        font-size: 15px;
        font-weight: bold;
      }

      .cancel-btn {
        border: solid 2px #4c4c4c;
        color: #4c4c4c;
        margin-left: 6px !important;
      }
    }

    .main-search,
    .main-filter:not(.is-header) {
      padding: 12px 25px;
      border-radius: 24px !important;
    }

    .main-filter {
      color: #465156;
    }
    .main-filter.btn {
      font-weight: 500;
      font-size: 15px;
    }

    .input-extension-button {
      float: right;
      margin-right: 17px;
      margin-top: -38px;
      position: relative;
      z-index: 2;
      font-size: 15px;
      color: var(--primary-white);
      background-color: var(--secondary-green) !important;
    }

    .main-filters {
      box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.06),
        0 0 16px 0 rgba(0, 0, 0, 0.12);
      margin-top: 10px;
      color: #4c4c4c;
    }

    .search-description {
      padding-top: 10px;
      font-size: 13px;
      font-weight: 500;
    }
  }

  .btn-group + .btn-group {
    margin-left: 0px;
  }

  .filter-tags {
    background-color: #465156;
    color: var(--primary-white);
    padding: 5px 10px;
    font-size: 16px;
    font-weight: normal;
    border-radius: 100px;
    margin: 2px;
    display: inline-block;
    white-space: normal;
  }

  .filter-tags .fa {
    cursor: pointer;
    color: #8e979b;
    font-size: 18px;
    margin-left: 5px;
  }
  .filter-tags .fa:hover {
    color: var(--primary-white);
  }

  .extra-action-buttons {
    margin-bottom: 15px;
    margin-top: -15px;
  }
  .extra-action-buttons .btn span {
    font-size: 15px;
    font-weight: 500;
  }
  .extra-action-buttons .btn .fa {
    font-size: 18px;
  }

  .breeder-form-v2 {
    label {
      text-align: left !important;
      margin-bottom: 9px;
    }

    .sub-label {
      font-size: 14px;
      color: var(--secondary-grey);
    }

    .breeder-weight-bottom-margin {
      padding-left: 0;
    }

    .form-group {
      margin-left: 0;
      margin-right: 0;
    }

    .image-dropzone {
      border: 1px dashed var(--border-grey);
      border-radius: 5px;
      height: 200px;
      background-color: var(--dropzone-bg);
      justify-content: center;
    }
  }

  .pagination {
    margin: 0;

    a {
      font-weight: 500;
    }
    li:not(.active) a {
      color: var(--primary-green) !important;
    }
  }

  .pagination-description {
    text-align: center;
    position: absolute;
    top: 7px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    color: var(--primary-green);
    font-weight: 500;
  }

  .row.no-extra-margin {
    margin-left: 0;
    margin-right: 0;
  }

  .pt-15 {
    padding-top: 15px;
  }

  .tile-breeder-image {
    margin-right: 5%;

    > img {
      width: 100px;
      height: auto;
      border: 3px solid var(--primary-white);
      background: var(--primary-white);
    }
  }

  .load-more .btn {
    padding: 6px 12px;
    border-radius: 100px;
    font-size: 14px;
  }

  .wizard-header {
    background-image: url(/media/wizard/breeders/fill-1@2x.png);
    background-size: 450px;
    background-repeat: no-repeat;
    border-radius: 8px;
    margin-bottom: 30px;
    box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.07);

    .header-container {
      padding: 50px 0;
    }

    .title {
      color: var(--primary-green);
      font-size: 35px;
      font-weight: 500;
    }

    .description {
      color: var(--secondary-grey);
      font-size: 18px;
    }
  }

  .wizard-item {
    border-radius: 8px;
    box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.07);

    .header {
      img {
        object-fit: contain;
        width: 100%;
      }
    }

    .body {
      padding: 10px 20px;
      min-height: 250px;

      .title {
        font-size: 24px;
        margin-bottom: 10px;
        color: var(--gunmetal);
      }

      .description {
        font-size: 16px;
        color: var(--secondary-grey);
      }
    }

    .btn {
      border-radius: 100px;
      font-size: 16px;
      font-weight: 500;
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: 80%;
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .description-header {
      font-size: 18px !important;
    }
    .description-header .unit {
      font-size: 14px !important;
    }

    .tile-breeder-image > img {
      width: 50px;
    }
    .widget-user:not(.pedigree-v2) .widget-user-desc {
      font-size: 13px;
    }
  }
  @media (max-width: 396px) {
    .tile-breeder-image > img {
      width: 70px;
    }
  }
  @media (max-width: 480px) {
    .description-header {
      font-size: 18px !important;
    }
    .description-header .unit {
      font-size: 14px !important;
    }

    .widget-user .widget-user-header {
      height: 130px;
    }

    .widget-user {
      .widget-user-image {
        top: 65px;
      }
      .box-footer {
        padding-top: 30px !important;
      }
    }
  }
  @media (max-width: 768px) {
    .main-filters {
      width: 100%;
    }

    .web-only {
      display: none !important;
    }

    .content-header {
      background-color: var(--primary-white);
    }

    .breedlist-header {
      > .flex-center {
        flex-direction: row-reverse;
        flex-wrap: wrap-reverse;
      }

      .breedlist-header-title {
        order: 1;
        margin-right: auto;
      }

      .breedlist-header-actions {
        text-align: center;
        margin-top: 10px;
        width: 100%;
      }
    }

    .main-table-filters {
      padding: 0px;

      > div:last-child {
        margin-top: 10px;
      }

      .btn-group {
        width: 100%;
      }
      .btn {
        width: 100%;
      }

      .filter-actions .cancel-btn {
        margin-left: 0px !important;
        margin: 5px 0px;
      }

      .main-filter > .fa {
        margin-left: auto;
      }
    }

    .table-header {
      flex-direction: row-reverse;
      flex-wrap: wrap-reverse;
      padding: 0;

      .table-title {
        order: 1;
        margin-right: auto;
      }

      .table-filters {
        overflow: auto;
        white-space: nowrap;
        text-align: center;
        margin-top: 10px;
        width: 100%;
      }

      .table-actions {
        margin-left: auto;

        > span {
          display: none;
        }

        .btn {
          font-size: 10px;
        }

        .fa {
          font-size: 15px !important;
        }
      }
    }

    .table-responsive {
      padding: 0;

      > .table-v2 {
        th,
        td {
          padding-right: 1.5% !important;
        }
      }
    }

    .table-responsive.freeze-first-child > .table-v2 {
      > thead > tr > th:first-child,
      > tbody > tr > td:first-child {
        position: sticky;
        left: 0px;
        z-index: 9;
      }

      > thead > tr:not(.litter-container.active) th:first-child {
        background-color: var(--table-light-grey);
      }
      > tbody > tr:not(.litter-container.active) td:first-child {
        background-color: var(--primary-white);
      }
    }

    .pagination-container {
      text-align: center;
    }

    .pagination {
      margin-top: 40px;
    }
  }

  .bred-icon.butchered-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-bottom: 6px;
    }
  }

  .transfer-actions {
    position: absolute;
    right: 15px;
    bottom: 15px;

    .btn {
      display: block;
      width: 100%;
      padding: 4px 12px;
      border-radius: 8px;
      font-size: 12px;
      font-weight: bold;
      border-color: transparent;
      margin-bottom: 5px;
    }
  }

  .bg-maroon-active .transfer-actions .btn {
    background-color: #f5d8d8;
  }

  .bg-aqua-active .transfer-actions .btn {
    background-color: #d7f7ff;
  }

  .inline-transfer-actions {
    .btn {
      width: 80px;
      padding: 4px 12px;
      border-radius: 8px;
      font-size: 12px;
      font-weight: bold;
      border-color: transparent;
      margin-bottom: 5px;
    }

    .claim {
      color: var(--primary-white);
      background-color: #03985d;
    }

    .decline {
      background-color: #f5d8d8;
    }
  }

  @media (min-width: 768px) {
    .main-filters {
      min-width: 650px !important;
    }

    .mobile-only {
      display: none !important;
    }

    .table-header .table-actions {
      margin-left: auto;
    }

    .pagination-container:not(.text-center) {
      text-align: right;
    }
  }
  @media (min-width: 1000px) and (max-width: 1400px) {
    .description-header {
      font-size: 18px !important;
    }
    .description-header .unit {
      font-size: 14px !important;
    }
    .tile-breeder-content {
      width: 45%;

      > .widget-user-username {
        font-size: 18px !important;
      }

      > .widget-user-desc {
        width: 130%;
      }
    }
  }
  @media (min-width: 1400px) and (max-width: 1600px) {
    .tile-breeder-content {
      width: 45%;
    }
  }
  @media (min-width: 1600px) and (max-width: 1800px) {
    .tile-breeder-content {
      width: 55%;
    }
  }
  @media (min-width: 1800px) {
    .tile-breeder-content {
      width: 60%;
    }
  }
  @media (max-width: 530px) {
    .tile-view > .col-lg-4 {
      padding: 0 !important;
    }

    .tile-breeder-content {
      width: 45%;

      > .widget-user-username {
        font-size: 20px !important;
      }

      > .widget-user-desc {
        width: 130%;
      }
    }
  }
}
