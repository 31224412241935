.litter-profile-v2 .flex-center .pull-right {
  margin-left: auto;
}
.litter-profile-v2 .flex-center .pull-left {
  margin-right: auto;
}
.litter-profile-v2 .widget-user-header .litter-quick-data {
  border-left: 2px solid var(--primary-white);
  align-self: stretch;
  font-size: 12px;
  min-width: 185px;
}
.litter-profile-v2 .widget-user-header .litter-quick-data .title {
  font-weight: 700;
}
.litter-profile-v2 .widget-user-header .litter-quick-data .value {
  font-weight: 400;
}
.litter-profile-v2 .widget-user-header .litter-actions .btn {
  background-color: transparent;
  padding: 5px 15px;
  font-size: 16px;
  font-weight: 500;
  border-color: var(--border-light-grey);
  color: var(--primary-white);
}
.litter-profile-v2 .widget-user-header .litter-actions .btn .fa {
  font-size: 30px;
}
.litter-profile-v2 .widget-user-header .litter-actions .dropdown-menu {
  position: absolute;
  right: 0;
  left: auto;
}
.litter-profile-v2 .kits-header {
  padding-top: 20px;
}
.litter-profile-v2 .kits-header .kits-header-item {
  color: var(--primary-white);
  height: 110px;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 15px;
}
.litter-profile-v2 .kits-header .kits-header-item .kits-header-title {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
}
.litter-profile-v2 .kits-header .kits-header-item .kits-header-value {
  font-size: 38px;
  line-height: 48px;
  font-weight: bold;
  margin-bottom: 5px;
  text-overflow: ellipsis;
}
.litter-profile-v2 .kits-header .kits-header-item .kits-header-value .unit {
  margin-left: 3px;
  line-height: normal;
}
.litter-profile-v2 .dead-kits-icon img {
  vertical-align: top;
  cursor: pointer;
}
.litter-profile-v2 #undo-dead-kits-modal-v2 .modal-content .main-label {
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.8px;
  margin: 20px 0;
}
.litter-profile-v2 #undo-dead-kits-modal-v2 .modal-content .sub-label {
  font-size: 15px;
  font-weight: 500;
  margin: 10px 0;
}
.litter-profile-v2 #undo-dead-kits-modal-v2 .modal-content .icheck-group .icheck-label {
  padding-left: 15px;
}
.litter-profile-v2 #undo-dead-kits-modal-v2 .modal-content .icheck-group .col-md-6 {
  margin-top: 10px;
}
.litter-profile-v2 .kits-content .kits-content-item {
  display: flex;
  min-height: 95px;
  height: 100%;
  border-radius: 4px;
  border: solid 1px #d5d5d5;
}
.litter-profile-v2 .kits-content .kits-content-item .kit-image {
  width: 30%;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.litter-profile-v2 .kits-content .kits-content-item .kit-image img {
  object-fit: contain;
  width: 63px;
  border: 3px solid #979797 !important;
}
.litter-profile-v2 .kits-content .kits-content-item .kit-data {
  width: 70%;
  padding: 8px 15px;
  background-color: #f0f0f0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.litter-profile-v2 .kits-content .kits-content-item .kit-data .title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  width: 70%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.litter-profile-v2 .kits-content .kits-content-item .kit-data .description {
  font-size: 14px;
  margin-bottom: 5px;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.litter-profile-v2 .kits-content .kits-content-item .kit-data .btn-custom-action .fa {
  color: #979797;
  font-size: 6px;
}
.litter-profile-v2 .kits-content .kits-content-item .kit-data .btn-group.open .dropdown-toggle.btn-custom-action {
  box-shadow: none;
}
.litter-profile-v2 .kits-content .kits-content-item .kit-data .extras a.weights {
  font-size: 13px;
  color: #8a8a8a;
}
.litter-profile-v2 .kits-content .kits-content-item .kit-data .extras .status {
  height: 25px;
}
.litter-profile-v2 .kits-content .kits-content-item .kit-data .extras .status a {
  border: 1px solid white;
  box-shadow: 0px 0px 1px #888;
  border-radius: 50%;
}
.litter-profile-v2 .kits-content .kits-content-item .kit-data .extras .status a .fa {
  width: 24px;
  height: 24px;
  font-size: 14px;
  color: var(--primary-white);
  border: 1.5px solid var(--primary-white);
}
.litter-profile-v2 .kits-content .kits-content-item .kit-data .extras .status a .butchered-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid var(--primary-white);
}
.litter-profile-v2 .kits-content .kits-content-item .kit-data .extras .status a .butchered-icon img {
  object-fit: contain;
}
.litter-profile-v2 .litter-box-tile-view {
  border-radius: 4px;
}
.litter-profile-v2 .litter-box-tile-view .breeder-profile.kits-header .kits-header-item {
  margin-bottom: 0px;
}
.litter-profile-v2 .litter-box-tile-view .breeder-profile.kits-header .litter-profile {
  padding-left: 0px;
}
.litter-profile-v2 .litter-box-tile-view .breeder-profile.kits-header .litter-profile .kits-header-item {
  border-bottom-left-radius: 0px;
}
.litter-profile-v2 .litter-box-tile-view .breeder-profile.kits-header .litter-profile .kits-header-value {
  font-size: 17px;
  line-height: normal;
}
.litter-profile-v2 .litter-box-tile-view .breeder-profile.kits-header .litter-profile .kits-header-title {
  margin-bottom: 0px;
}
.litter-profile-v2 .litter-box-tile-view .breeder-profile.kits-header .litter-data {
  padding-right: 0px;
}
.litter-profile-v2 .litter-box-tile-view .breeder-profile.kits-header .litter-data .row > .col-md-3:last-child {
  padding-right: 0px;
}
.litter-profile-v2 .litter-box-tile-view .breeder-profile.kits-header .litter-data .row > .col-md-3:last-child .kits-header-item {
  border-bottom-right-radius: 0px;
}
.litter-profile-v2 .litter-box-tile-view .breeder-profile.kits-header .litter-data .kits-header-title {
  font-size: 10px;
  font-weight: 500;
  opacity: 0.5;
}
.litter-profile-v2 .litter-box-tile-view .breeder-profile.kits-header .litter-data .kits-header-value {
  font-size: 17px;
  line-height: normal;
  font-weight: 500;
  position: absolute;
  bottom: 15px;
  margin-bottom: 0px;
  width: calc(100% - 30px);
}
.litter-profile-v2 .litter-box-tile-view .kits-tile-container {
  box-shadow: 0px 0px 10px var(--lighter-grey);
  padding: 10px 0 20px 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.litter-profile-v2 .litter-box-tile-view .litter-actions {
  position: absolute;
  right: 15px;
  top: 40px;
}
.litter-profile-v2 .litter-box-tile-view .litter-actions .btn > .fa {
  color: var(--primary-white);
}
.litter-profile-v2 .litter-box-tile-view .undo-dead-kits-button {
  border-radius: 5px;
  margin-top: 8px;
  margin-bottom: -10px;
  width: 100%;
  color: var(--primary-white);
  border: solid 0.8px var(--primary-white);
  background-color: #6b887d;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  display: block;
}
@media (max-width: 480px) {
  .litter-profile-v2:not(.litter-box-tile-container) .description-header {
    font-size: 30px !important;
  }
}
@media (min-width: 1000px) and (max-width: 1400px) {
  .litter-profile-v2:not(.litter-box-tile-container) .description-header {
    font-size: 30px !important;
  }
  .litter-profile-v2 .kits-content .kits-content-item .kit-image img {
    width: 55px;
    height: 55px;
    border-width: 2px !important;
  }
  .litter-profile-v2 .litter-box-tile-view .breeder-profile.kits-header .litter-data .kits-header-value {
    font-size: 14px !important;
  }
  .litter-profile-v2 .kits-header.no-gutter .col-md-6 .kits-header-item .row.no-margin > div {
    height: 80px;
  }
  .litter-profile-v2 .kits-header.no-gutter .kits-header-value {
    font-size: 30px !important;
    line-height: normal !important;
  }
  .litter-profile-v2 .kits-content .kits-content-item .kit-data .title {
    font-size: 14px !important;
  }
  .litter-profile-v2 .kits-content .kits-content-item .kit-data .description {
    font-size: 12px !important;
  }
}
@media (min-width: 1000px) and (max-width: 1200px) {
  .litter-profile-v2 .kits-content .kits-content-item .kit-image img {
    width: 60px;
    height: 60px;
    border-width: 2px !important;
  }
}
@media (min-width: 768px) {
  #litters > .profile-actions {
    display: flex;
    align-items: center;
  }
}
@media (max-width: 768px) {
  .litter-box-tile-container {
    padding: 0;
  }
  .litter-box-tile-view .kits-header * {
    border-radius: unset !important;
  }
  .litter-box-tile-view .breeder-profile.kits-header .litter-profile {
    padding: 0px !important;
    margin-bottom: 5px;
  }
  .litter-box-tile-view .breeder-profile.kits-header .litter-data .row > .col-md-3 {
    padding: 0px !important;
  }
  .litter-box-tile-view .breeder-profile.kits-header .litter-data .col-md-3:last-child {
    margin-top: 5px;
  }
  .litter-box-tile-view .breeder-profile.kits-header .litter-data .undo-dead-kits-button {
    position: absolute;
    width: 100px;
    border-radius: 5px !important;
    border-color: transparent;
    padding: 10px !important;
    right: 0;
    top: -41px;
  }
  .litter-box-tile-view .breeder-profile.kits-header .dropdown-menu.breed-profile-action {
    position: absolute;
    right: 0;
    left: auto;
  }
}
@media (max-width: 991px) {
  .litter-profile-v2 .kits-header .kits-header-item {
    height: 100% !important;
  }
}
@media (min-width: 1600px) {
  .litter-profile-v2 .kits-content .kits-content-item .kit-image img {
    width: 70px;
    height: 70px;
  }
}
@media (max-width: 767px) {
  .litter-profile-v2 .kits-content .kits-content-item .kit-image img {
    width: 75px;
    height: 75px;
  }
}
@media (max-width: 1000px) {
  .litter-profile-v2 .litter-box-tile-view .kits-tile-container {
    border-radius: 4px !important;
  }
  .litter-profile-v2 .litter-box-tile-view .breeder-profile.kits-header .litter-data {
    padding: 0px !important;
  }
  .litter-profile-v2 .litter-box-tile-view .breeder-profile.kits-header .litter-data .kits-header-value {
    position: unset !important;
  }
  .litter-profile-v2 .litter-box-tile-view .breeder-profile.kits-header .litter-data .row > .col-md-3:last-child .kits-header-item {
    border-bottom-right-radius: 4px !important;
  }
  .litter-profile-v2 .litter-box-tile-view .breeder-profile.kits-header .litter-data .row > * {
    padding: 0px !important;
  }
  .litter-profile-v2 .litter-box-tile-view .breeder-profile.kits-header .litter-profile {
    padding: 0px !important;
  }
  .litter-profile-v2 .litter-box-tile-view .breeder-profile.kits-header .litter-profile .kits-header-item {
    border-bottom-left-radius: 4px !important;
  }
}
