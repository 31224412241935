.settings-v2 {
  .box-header.breedlist-header {
    padding: 0 15px;
  }

  .modal-nav:not(.border-btm) {
    border-bottom: 3px transparent solid;
  }

  .box.box-solid.box-primary.settings {
    border-radius: 10px;
    border-color: transparent;

    .box-header {
      background-color: var(--gunmetal) !important;
      color: var(--primary-white);
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      padding: 10px 20px;

      .title {
        font-size: 18px;
        font-weight: bold;
      }
    }

    .box-footer {
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      padding: 30px;

      button.btn {
        margin: 0px 5px;
        padding: 8px 20px;
        font-size: 12px;
        min-width: 90px;
      }
    }

    button.btn:not(.table-btn) {
      border-radius: 100px;
      font-weight: 550;

      .fa {
        font-size: 18px;
        width: 20px;
      }
    }

    .form-group {
      button.btn:not(:first-child) {
        margin-left: 5px;
      }
    }

    button.btn.btn-custom-action {
      .fa {
        color: var(--secondary-grey);
        font-size: 6px;
        width: auto;
      }
    }

    .timeline > li {
      margin-bottom: 0;
    }

    .timeline-inverse > li > .timeline-item {
      background: none;
      border: none;
      box-shadow: none;

      .timeline-header {
        border-bottom: none;

        > * {
          margin: 0 5px;
        }

        .time {
          width: 60px;
        }

        .title {
          min-width: 70px;
        }
      }
    }

    .timeline > li > .fa,
    .timeline > li > .glyphicon,
    .timeline > li > .ion {
      top: 13px;
    }

    .timeline:before {
      top: 13px;
      height: 100%;
    }

    .timeline-header {
      span {
        font-size: 14px;
      }
    }
  }

  .sub-label {
    font-size: 12px;
    color: var(--secondary-grey);
  }

  .rabbitry-settings-warning {
    min-height: 80px;
    width: 100%;
    margin: 30px 0;
    border-radius: 6px;
    background-color: #f4f4f4;
    padding: 30px;

    .title {
      font-size: 20px;
      font-weight: bold;

      img {
        margin-right: 20px;
      }
    }

    .description {
      font-size: 20px;
      margin-left: 10px;
    }
  }

  .rabbitry-settings-warning-sm {
    min-height: 40px;
    margin: 10px 0;
    padding: 10px 30px;

    .title {
      font-size: 14px;

      img {
        margin-right: 20px;
      }
    }

    .description {
      font-size: 14px;
    }
  }

  .settings-image-upload {
    .image-container {
      justify-content: center;

      .image {
        position: relative;

        img {
          width: 85px;
          height: 85px;
          object-fit: cover;
          display: block;
          margin-top: 0 !important;
          float: left;
          cursor: default;
        }

        .fa {
          position: absolute;
          right: -15px;
          top: 25px;
          background-color: var(--primary-green);
          color: white;
          font-size: 18px;
          padding: 0.5em;
          -webkit-text-stroke: revert;
          cursor: pointer;
        }
      }
    }
  }

  .treatment-tasks-container {
    background-color: #f3f3f3;
    border-radius: 14px;
    padding: 20px;
  }

  @media (max-width: 768px) {
    .box-header.breedlist-header {
      padding-top: 25px;
    }
  }
}
