.litters-v2 {
  .main-filters {
    .select2 {
      width: 100% !important;
    }
  }

  .bg-olive {
    background-color: var(--secondary-green) !important;
  }

  .widget-user .box-footer {
    padding-top: 40px;
  }

  .litter-list-image {
    img {
      margin-right: 5px;
      border: 2px solid;
    }
  }

  .layout-switch-button.profile-actions {
    display: inline-flex;
    margin-bottom: 0px;

    .actions {
      margin-right: 20px;
    }
  }

  .breedlist-header-actions {
    .fa.fa-sort-amount-asc {
      font-size: 16px;
    }
  }

  .description-header {
    font-size: 24px;
    white-space: nowrap;

    .unit {
      font-size: 17px;
    }
  }

  #litter-weight-modal,
  #global-litter-weight-modal,
  #cage-weight-modal,
  #global-cage-weight-modal,
  #cage-sell-modal,
  #litter-sell-modal {
    .info-box {
      border-radius: 4px;

      .info-box-number {
        font-size: 16px;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .info-box-icon {
        img {
          max-width: 70%;
          margin: 15px auto;
          border: 3px solid;
        }
      }

      .info-box-content {
        min-height: 90px;
        color: black;
        padding: 5px 15px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
    
    .info-box.bg-maroon .info-box-content {
      background-color: #fae4ec;
      border: 1px solid #d81a61;
    }

    .info-box.bg-aqua .info-box-content {
      background-color: #e5faff;
      border: 1px solid #0aa7d0;
    }
  }

  .litter-butcher-table td {
    border-color: transparent !important;
  }

  .transfer-field {
    justify-content: center;
  }

  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .description-text {
      font-size: 10px !important;
    }
    .description-header {
      font-size: 17px !important;
    }
    .description-header .unit {
      font-size: 12px !important;
    }
    .litter.widget-user-image {
      margin-left: -45px;
      top: 80px;

      > img {
        width: 80px !important;
      }
    }

    .widget-user {
      .box-footer {
        .row {
          margin: 0 -10px;
          > div {
            padding: 0 5px !important;
          }
        }
      }
    }

    .tile-breeder-content {
      width: 75%;
    }
  }
}
