.table-responsive {
  .table-v2.table-responsive-v2 {
    thead > tr > th {
      padding-left: 0 !important;
      padding-right: 0 !important;

      > div {
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
    }

    thead > tr > th:first-child {
      > div:not(.is-action) {
        min-width: 120px;
      }
    }

    .label-v2 {
      border-radius: 100px;
      font-size: 12px;
      padding: 5px 10px;
      font-weight: 500;
    }

    .label-v2.label-warning {
      background: rgba(255, 160, 0, 0.16) !important;
      color: #ffa000 !important;
    }
    .label-v2.label-success {
      background: rgba(76, 175, 80, 0.15) !important;
      color: #4caf50 !important;
    }

    @media (max-width: 1400px) {
      tbody > tr > td {
        padding: 10px !important;
      }
    }
  }
}
