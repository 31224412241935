.referrals-v2 .banner {
  min-height: 250px;
  border-radius: 6px;
  background-color: #ebf2ea;
  position: relative;
  padding: 50px 75px;
}
.referrals-v2 .banner .main {
  z-index: 2;
}
.referrals-v2 .banner .main .title {
  font-weight: 600;
  font-size: 45px;
}
.referrals-v2 .banner .main .description {
  font-size: 29px;
}
.referrals-v2 .banner .image {
  position: absolute;
  width: 350px;
  right: 50px;
  bottom: -18px;
  z-index: 1;
}
.referrals-v2 .banner .image img {
  object-fit: contain;
  width: 100%;
}
.referrals-v2 .steps .row {
  padding: 30px;
  background: var(--secondary-green);
  border-radius: 9px;
  color: var(--primary-white);
}
.referrals-v2 .steps .row .step-count {
  border-radius: 50%;
  padding: 5px;
  width: 30px;
  height: 30px;
  text-align: center;
  color: var(--secondary-green);
  background: var(--primary-white);
  margin-right: 10px;
  font-weight: bold;
}
.referrals-v2 .steps .row .step-content {
  font-size: 14px;
  width: 80%;
}
.referrals-v2 .steps .row .step-content .title {
  font-size: 15px;
  font-weight: bold;
}
.referrals-v2 .forms .form-label {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
}
.referrals-v2 .forms .form-label-sm {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}
.referrals-v2 .forms .form-container {
  border: 2px solid #eeeeee;
  border-radius: 9px;
  padding: 30px;
}
.referrals-v2 .forms .form-container .actions {
  text-align: right;
  margin-top: 30px;
}
.referrals-v2 .forms .form-container .actions button {
  border-radius: 100px;
  padding: 6px 50px;
  font-size: 12px;
  font-weight: 600;
}
.referrals-v2 .forms .invite-link {
  border: 1px solid #d2d6de;
  padding: 10px;
  height: 34px;
  border-radius: 5px;
}
.referrals-v2 .forms .invite-link .fa-copy {
  color: var(--primary-green);
  font-size: 20px;
  cursor: pointer;
}
.referrals-v2 .forms .invite-link .fa-copy:hover {
  color: var(--secondary-green);
}
.referrals-v2 .forms .socials-button:hover {
  box-shadow: 0px 0px 2px #888;
}
.referrals-v2 .forms .socials-button {
  display: flex;
  background-color: #eeeeee;
  padding: 15px;
  position: relative;
  border-radius: 150px;
  height: 50px;
  margin-top: 10px;
  cursor: pointer;
}
.referrals-v2 .forms .socials-button .icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid #eeee;
  padding: 10px;
}
.referrals-v2 .forms .socials-button .icon img {
  object-fit: contain;
  width: 100%;
}
.referrals-v2 .forms .socials-button .instagram {
  background-color: #fff;
}
.referrals-v2 .forms .socials-button .facebook {
  background-color: #3b5998;
}
.referrals-v2 .forms .socials-button .facebook img {
  filter: brightness(0) invert(1);
}
.referrals-v2 .forms .socials-button .twitter {
  background-color: #55acee;
}
.referrals-v2 .forms .socials-button .twitter img {
  filter: brightness(0) invert(1);
}
.referrals-v2 .forms .socials-button .title {
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
}
.referrals-v2 .forms .review-button {
  display: flex;
  color: var(--primary-green);
  border: 2px solid var(--primary-green);
  padding: 15px;
  position: relative;
  border-radius: 150px;
  height: 50px;
  margin-top: 10px;
  cursor: pointer;
}
.referrals-v2 .forms .review-button .title {
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
}
.referrals-v2 .results .main {
  padding: 20px;
}
.referrals-v2 .results .main .title {
  font-size: 45px;
  font-weight: bold;
}
.referrals-v2 .results .main .description {
  font-size: 29px;
}
@media (max-width: 1366px) {
  .referrals-v2 .current-plan-pricing {
    flex-direction: column;
  }
  .referrals-v2 .current-plan-pricing .discount {
    margin-right: auto;
  }
}
