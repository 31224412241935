.dashboard-v2 .dashboard-main {
  padding: 50px;
}
.dashboard-v2 .dashboard-main .date {
  color: #3d3d3d;
  font-size: 18px;
}
.dashboard-v2 .dashboard-main .banner {
  min-height: 250px;
  border-radius: 6px;
  background-color: #ff950013;
  margin-top: 50px !important;
  padding: 50px;
}
.dashboard-v2 .dashboard-main .banner .title {
  font-size: 33px;
  font-weight: 500;
  color: #0f1222;
  margin-bottom: 10px;
}
.dashboard-v2 .dashboard-main .banner .description {
  font-size: 21px;
  color: #3d3d3d;
}
.dashboard-v2 .dashboard-main .banner .actions {
  margin-top: 20px;
}
.dashboard-v2 .dashboard-main .banner .actions .btn {
  border-radius: 100px;
  font-size: 12px;
}
.dashboard-v2 .dashboard-main .banner a {
  color: var(--primary-green) !important;
}
.dashboard-v2 .dashboard-main .banner .main {
  z-index: 2;
}
.dashboard-v2 .dashboard-main .banner .image {
  z-index: 1;
}
.dashboard-v2 .dashboard-main .image img {
  object-fit: contain;
  width: 100%;
}
@media (min-width: 992px) {
  .dashboard-v2 .banner .image {
    position: absolute;
    width: 450px;
    right: 50px;
    bottom: 50px;
  }
}
@media (max-width: 992px) {
  .dashboard-v2 .banner .image img {
    margin-top: 20px;
  }
}
