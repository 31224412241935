.referrals-v2 {
  .banner {
    min-height: 250px;
    border-radius: 6px;
    background-color: #ebf2ea;
    position: relative;
    padding: 50px 75px;

    .main {
      z-index: 2;

      .title {
        font-weight: 600;
        font-size: 45px;
      }

      .description {
        font-size: 29px;
      }
    }

    .image {
      position: absolute;
      width: 350px;
      right: 50px;
      bottom: -18px;
      z-index: 1;

      img {
        object-fit: contain;
        width: 100%;
      }
    }
  }

  .steps {
    .row {
      padding: 30px;
      background: var(--secondary-green);
      border-radius: 9px;
      color: var(--primary-white);

      .step-count {
        border-radius: 50%;
        padding: 5px;
        width: 30px;
        height: 30px;
        text-align: center;
        color: var(--secondary-green);
        background: var(--primary-white);
        margin-right: 10px;
        font-weight: bold;
      }

      .step-content {
        font-size: 14px;
        width: 80%;

        .title {
          font-size: 15px;
          font-weight: bold;
        }
      }
    }
  }

  .forms {
    .form-label {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    .form-label-sm {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    .form-container {
      border: 2px solid #eeeeee;
      border-radius: 9px;
      padding: 30px;

      .actions {
        text-align: right;
        margin-top: 30px;

        button {
          border-radius: 100px;
          padding: 6px 50px;
          font-size: 12px;
          font-weight: 600;
        }
      }
    }

    .invite-link {
      border: 1px solid #d2d6de;
      padding: 10px;
      height: 34px;
      border-radius: 5px;

      .fa-copy {
        color: var(--primary-green);
        font-size: 20px;
        cursor: pointer;
      }

      .fa-copy:hover {
        color: var(--secondary-green);
      }
    }

    .socials-button:hover {
      box-shadow: 0px 0px 2px #888;
    }
    .socials-button {
      display: flex;
      background-color: #eeeeee;
      padding: 15px;
      position: relative;
      border-radius: 150px;
      height: 50px;
      margin-top: 10px;
      cursor: pointer;

      .icon {
        position: absolute;
        left: 0;
        top: 0;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 3px solid #eeee;
        padding: 10px;

        img {
          object-fit: contain;
          width: 100%;
        }
      }

      .instagram {
        background-color: #fff;
      }
      .facebook {
        background-color: #3b5998;

        img {
          filter: brightness(0) invert(1);
        }
      }
      .twitter {
        background-color: #55acee;

        img {
          filter: brightness(0) invert(1);
        }
      }

      .title {
        margin-left: auto;
        margin-right: auto;
        font-weight: bold;
      }
    }

    .review-button {
      display: flex;
      color: var(--primary-green);
      border: 2px solid var(--primary-green);
      padding: 15px;
      position: relative;
      border-radius: 150px;
      height: 50px;
      margin-top: 10px;
      cursor: pointer;

      .title {
        margin-left: auto;
        margin-right: auto;
        font-weight: bold;
      }
    }
  }

  .results {
    .main {
      padding: 20px;

      .title {
        font-size: 45px;
        font-weight: bold;
      }
      .description {
        font-size: 29px;
      }
    }
  }

  @media (max-width: 1366px) {
    .current-plan-pricing {
      flex-direction: column;

      .discount {
        margin-right: auto;
      }
    }
  }
}
