.accounts-v2 .subscriptions .header {
  text-align: center;
}
.accounts-v2 .subscriptions .header .main-title {
  font-weight: 700;
  font-size: 42px;
}
.accounts-v2 .subscriptions .header .plan-status {
  font-size: 22px;
}
.accounts-v2 .subscriptions .header .main-description {
  font-size: 16px;
}
.accounts-v2 .subscriptions .header.current-plan-header {
  text-align: left;
}
.accounts-v2 .subscriptions .current-plan-container {
  padding: 0px 0px 70px 0px;
  border-radius: 9px;
  background-color: #e9f3e5;
}
.accounts-v2 .subscriptions .current-plan-container .current-plan-pricing {
  margin: 30px 20px;
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 1px solid var(--primary-green);
}
.accounts-v2 .subscriptions .current-plan-container .current-plan-pricing .price {
  border-radius: 6px;
  background: black;
  color: white;
  font-weight: bold;
  padding: 20px 40px;
  font-size: 35px;
  margin-right: 20px;
}
.accounts-v2 .subscriptions .current-plan-container .current-plan-pricing .description {
  font-size: 20px;
  font-weight: bold;
}
.accounts-v2 .subscriptions .current-plan-container .current-plan-pricing .discount {
  margin-left: auto;
  border: 2px dashed var(--primary-green);
  padding: 20px;
  border-radius: 8px;
  background: #fffef7;
  max-width: 280px;
}
.accounts-v2 .subscriptions .current-plan-container .current-plan-pricing .discount .title {
  font-size: 14px;
  font-weight: 600;
  float: left;
  width: 40%;
}
.accounts-v2 .subscriptions .current-plan-container .current-plan-pricing .discount .description {
  font-size: 19px;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--primary-green);
  float: left;
  width: 60%;
}
.accounts-v2 .subscriptions .current-plan-container .current-plan-details > * {
  padding: 0 40px;
}
.accounts-v2 .subscriptions .current-plan-container .current-plan-details {
  padding: 0;
}
.accounts-v2 .subscriptions .current-plan-container .current-plan-details .icon {
  padding: 4px;
  background-color: var(--primary-green);
  border-radius: 50%;
  width: 45px;
  height: 45px;
  margin-right: 15px;
}
.accounts-v2 .subscriptions .current-plan-container .current-plan-details .icon img {
  width: 36px;
  padding-top: 6px;
}
.accounts-v2 .subscriptions .current-plan-container .current-plan-details .title {
  font-size: 20px;
  font-weight: bold;
}
.accounts-v2 .subscriptions .current-plan-container .current-plan-details .description {
  font-size: 17px;
}
.accounts-v2 .subscriptions .current-plan-container .current-plan-details .plan-content .main {
  font-size: 19px;
}
.accounts-v2 .subscriptions .current-plan-container .current-plan-details .plan-content .description {
  font-size: 15px;
  color: var(--primary-green);
}
.accounts-v2 .subscriptions .current-plan-container .current-plan-details .amount {
  font-size: 19px;
  font-weight: bold;
}
.accounts-v2 .subscriptions .current-plan-container .current-plan-details .amount .discount {
  font-size: 14px;
  display: inline;
  font-weight: 500;
  margin-left: 10px;
}
.accounts-v2 .subscriptions .current-plan-container .current-plan-details .expiry {
  font-size: 15px;
}
.accounts-v2 .subscriptions .current-plan-container .current-plan-details .btn {
  border-radius: 100px;
  padding: 6px 30px;
  font-weight: 500;
}
.accounts-v2 .subscriptions .current-plan-container .current-plan-details .middle-content {
  background-color: #d5e4d0;
  margin: 30px 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.accounts-v2 .subscriptions .cancel-container {
  padding: 20px 40px;
}
.accounts-v2 .subscriptions .cancel-container .btn {
  float: right;
  border-radius: 100px;
  padding: 6px 30px;
  font-weight: 500;
}
.accounts-v2 .subscriptions .plan-box {
  min-height: 700px;
  position: relative;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.1);
}
.accounts-v2 .subscriptions .plan-box .most-popular {
  background: linear-gradient(180deg, #dd4b39 0%, #bc4031 100%);
  box-shadow: inset 0px -1px 5px rgba(0, 0, 0, 0.221774);
  position: absolute;
  top: -30px;
  padding: 5px;
  width: 100%;
  text-align: center;
  color: var(--primary-white);
  font-weight: bold;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.accounts-v2 .subscriptions .plan-box .header {
  height: 220px;
  padding: 40px;
  position: relative;
  color: var(--primary-white);
  background: linear-gradient(360deg, rgba(0, 99, 161, 0.7) 0%, rgba(0, 166, 226, 0.7) 100%);
}
.accounts-v2 .subscriptions .plan-box .header .plan-type {
  font-size: 19px;
  font-weight: 500;
}
.accounts-v2 .subscriptions .plan-box .header .plan-amount {
  font-size: 40px;
  font-weight: bolder;
}
.accounts-v2 .subscriptions .plan-box .header .plan-duration {
  font-size: 12px;
  font-weight: bold;
}
.accounts-v2 .subscriptions .plan-box .header .plan-description {
  font-size: 11px;
}
.accounts-v2 .subscriptions .plan-box .header::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background-repeat: repeat;
  height: 10px;
  background-size: 20px 20px;
  background-image: radial-gradient(circle at 10px -5px, transparent 12px, var(--primary-white) 13px);
}
.accounts-v2 .subscriptions .plan-box .header::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background-repeat: repeat;
  height: 15px;
  background-size: 40px 20px;
  background-image: radial-gradient(circle at 10px 15px, var(--primary-white) 12px, transparent 13px);
}
.accounts-v2 .subscriptions .plan-box .body {
  padding: 20px 20%;
}
.accounts-v2 .subscriptions .plan-box .body .plan-feature {
  font-size: 16px;
  margin-top: 10px;
}
.accounts-v2 .subscriptions .plan-box .body .plan-feature .main {
  font-weight: bold;
}
.accounts-v2 .subscriptions .plan-box .body .plan-feature .text {
  margin-left: 15px;
}
.accounts-v2 .subscriptions .plan-box .body .plan-feature .fa {
  color: var(--primary-green);
  font-size: 22px;
}
.accounts-v2 .subscriptions .plan-box .action-container {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.accounts-v2 .subscriptions .plan-box .action-container .upgrade-btn {
  border-radius: 100px;
  text-align: center;
  padding: 6px;
  font-weight: bold;
  width: 80%;
}
.accounts-v2 .subscriptions .plan-box .current-plan-v2 {
  background-color: var(--primary-white);
  color: var(--primary-green);
  border-width: 2px;
}
.accounts-v2 .subscriptions .plan-preview {
  border: 2px solid black;
  height: 200px;
  width: 400px;
  border-radius: 10px;
  padding: 20px;
  margin: 0px 10px;
}
.accounts-v2 .subscriptions .plan-preview .title {
  font-weight: bold;
  margin-bottom: 30px;
}
.accounts-v2 .subscriptions .plan-preview .entry {
  display: flex;
}
.accounts-v2 .subscriptions .plan-preview .entry .value {
  margin-left: auto;
}
.accounts-v2 .subscriptions .plan-preview.discount {
  width: 200px;
  height: auto;
}
.accounts-v2 .subscriptions .plan-preview.old-plan {
  opacity: 0.5;
  border-color: #878585;
}
.accounts-v2 .subscriptions .back-to-plans {
  background-color: transparent;
  display: flex;
  font-weight: 500;
  float: left;
  position: absolute;
}
.accounts-v2 .subscriptions .back-to-plans i {
  font-size: 21px;
  margin-right: 10px;
}
.accounts-v2 .payment-details {
  margin: 50px 70px;
}
.accounts-v2 .payment-details .title {
  font-weight: 700;
  font-size: 18px;
}
.accounts-v2 .payment-details .btn.btn-success {
  border-radius: 100px;
  padding: 6px 30px;
  font-weight: 500;
}
.accounts-v2 .payment-details .terms-and-conditions {
  font-size: 12px;
}
.accounts-v2 .payment-details .terms-and-conditions a {
  color: var(--primary-green);
  font-weight: 500;
}
.accounts-v2 .plan-duration-container .plan-duration-list {
  padding: 0;
}
.accounts-v2 .plan-duration-container .plan-duration-list .tab {
  margin-top: 1px;
  margin-bottom: 0;
  margin-left: 1px;
  margin-right: 0;
  display: inline-block;
  vertical-align: bottom;
  list-style: none;
}
.accounts-v2 .plan-duration-container .plan-duration-list .tab a {
  padding: 10px 25px;
  display: block;
  border: solid transparent;
  position: relative;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 100px;
  background: transparent;
  color: var(--primary-green);
  font-weight: 500;
  border: 2px solid var(--primary-green);
  opacity: 0.5;
}
.accounts-v2 .plan-duration-container .plan-duration-list .tab.active a {
  background: var(--primary-green);
  color: var(--primary-white);
  opacity: 1;
}
.accounts-v2 .plan-duration-container .plan-duration-list .tab.toggle a {
  background: none;
  color: var(--primary-green);
  border: none;
  font-size: 40px;
  padding: 2px 5px;
  opacity: 1;
}
.accounts-v2 .alert-v2 .title {
  font-size: 18px;
  font-weight: 500;
}
.accounts-v2 .alert-v2 .description {
  font-size: 14px;
  font-weight: 400;
}
@media (max-width: 750px) {
  .accounts-v2 .current-plan-pricing {
    flex-direction: column;
  }
  .accounts-v2 .current-plan-pricing .discount {
    margin-right: auto;
  }
}
