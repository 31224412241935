.dashboard-v2 {
  .dashboard-main {
    padding: 50px;

    .date {
      color: #3d3d3d;
      font-size: 18px;
    }

    .banner {
      min-height: 250px;
      border-radius: 6px;
      background-color: #ff950013;
      margin-top: 50px !important;
      padding: 50px;

      .title {
        font-size: 33px;
        font-weight: 500;
        color: #0f1222;
        margin-bottom: 10px;
      }

      .description {
        font-size: 21px;
        color: #3d3d3d;
      }

      .actions {
        margin-top: 20px;

        .btn {
          border-radius: 100px;
          font-size: 12px;
        }
      }

      a {
        color: var(--primary-green) !important;
      }

      .main {
        z-index: 2;
      }

      .image {
        z-index: 1;
      }
    }

    .image {
      img {
        object-fit: contain;
        width: 100%;
      }
    }
  }

  @media (min-width: 992px) {
    .banner .image {
      position: absolute;
      width: 450px;
      right: 50px;
      bottom: 50px;
    }
  }
  @media (max-width: 992px) {
    .banner .image {
      img {
        margin-top: 20px;
      }
    }
  }
}
