/*
 * Skin: Yellow
 * ------------
 */
@import "../../bootstrap-less/mixins.less";
@import "../../bootstrap-less/variables.less";
@import "../variables.less";
@import "../mixins.less";

.skin-yellow {
  //Navbar
  .main-header {
    .navbar {
      .navbar-variant(@yellow; #fff);
      .sidebar-toggle {
        color: #fff;
        &:hover {
          background-color: darken(@yellow, 5%);
        }
      }
      @media (max-width: @screen-header-collapse) {
        .dropdown-menu {
          li {
            &.divider {
              background-color: rgba(255, 255, 255, 0.1);
            }
            a {
              color: #fff;
              &:hover {
                background: darken(@yellow, 5%);
              }
            }
          }
        }
      }
    }
    //Logo
    .logo {
      .logo-variant(darken(@yellow, 5%));
    }

    li.user-header {
      background-color: @yellow;
    }
  }

  //Content Header
  .content-header {
    background: transparent;
  }

  //Create the sidebar skin
  .skin-dark-sidebar(@yellow);
}
