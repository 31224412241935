.profile-v2 .box.box-widget {
  border-radius: 8px;
  border: 2px solid transparent;
}
.profile-v2 .breeder-sex-icon {
  background-color: var(--primary-white);
  margin-left: auto;
}
.profile-v2 .bg-maroon-v1 .breeder-sex-icon {
  color: #d81a61;
}
.profile-v2 .bg-aqua-v1 .breeder-sex-icon {
  color: #0092bb;
}
.profile-v2 .widget-user-2 .widget-user-header:not(.pedigree-image) {
  padding: 10px 30px;
}
.profile-v2 .widget-user-image:not(.pedigree-v2-image) .img-circle:not(.pedigree-card-2) {
  margin-left: 0px;
  border: 2px solid;
}
.profile-v2 .widget-user-2 .widget-user-username:not(.pedigree-user-username),
.profile-v2 .widget-user-2 .widget-user-desc:not(.pedigree-user-desc) {
  margin-left: 25px;
}
.profile-v2 .widget-user-desc:not(.pedigree-user-desc) {
  font-size: 13px;
  font-weight: 500;
}
.profile-v2 .widget-user-username:not(.pedigree-user-username) {
  font-size: 32px !important;
  line-height: 32px;
  font-weight: 500;
}
.profile-v2 .widget-user-username:not(.pedigree-user-username) .sub-name {
  font-size: 14px;
  font-weight: normal;
}
.profile-v2 .box-widget .nav-pills:not(.pedigree-settings, .form) {
  padding: 25px 25px 10px 25px;
}
.profile-v2 .box-widget .nav-pills:not(.pedigree-settings, .form) .placeholder {
  pointer-events: none;
}
.profile-v2 .box-widget .tab-content:not(.form) {
  padding: 0 25px;
}
.profile-v2 .box-widget .tab-content:not(.form) .profile-content {
  border-radius: 4px;
  border: solid 1px var(--generic-bg);
  padding: 10px;
}
.profile-v2 .box-widget .tab-content:not(.form) .profile-content .profile-content-item {
  padding: 10px;
  border-bottom: solid 1px var(--generic-bg);
}
.profile-v2 .box-widget .tab-content:not(.form) .profile-content .profile-content-item .profile-content-title {
  font-size: 14px;
  font-weight: 500;
  color: var(--gunmetal);
}
.profile-v2 .box-widget .tab-content:not(.form) .profile-content .profile-content-item .profile-content-value {
  font-size: 12px;
  font-weight: 500;
  color: var(--border-grey);
  margin-left: auto;
}
.profile-v2 .box-widget .tab-content:not(.form) .profile-content .profile-content-item .profile-parents {
  width: 100%;
  padding: 20px 0;
  display: inline-flex;
  text-align: center;
}
.profile-v2 .box-widget .tab-content:not(.form) .profile-content .profile-content-item .profile-parents .profile-father,
.profile-v2 .box-widget .tab-content:not(.form) .profile-content .profile-content-item .profile-parents .profile-mother {
  width: 50%;
  cursor: not-allowed;
}
.profile-v2 .box-widget .tab-content:not(.form) .profile-content .profile-content-item .profile-parents .profile-father.available,
.profile-v2 .box-widget .tab-content:not(.form) .profile-content .profile-content-item .profile-parents .profile-mother.available {
  cursor: pointer;
}
.profile-v2 .box-widget .tab-content:not(.form) .profile-content .profile-content-item .profile-parents .profile-father.available:hover,
.profile-v2 .box-widget .tab-content:not(.form) .profile-content .profile-content-item .profile-parents .profile-mother.available:hover {
  opacity: 0.7;
}
.profile-v2 .box-widget .tab-content:not(.form) .profile-content .profile-content-item .profile-parents .profile-father {
  border-right: solid 1px var(--footer-grey);
}
.profile-v2 .box-widget .tab-content:not(.form) .profile-content .profile-content-item.is-parent {
  padding: 0 10px;
  border-bottom: none;
  background-color: var(--generic-bg);
}
.profile-v2 .box-widget .tab-content:not(.form) .main-image,
.profile-v2 .box-widget .tab-content:not(.form) .sub-image {
  object-fit: cover;
  width: 100%;
}
.profile-v2 .box-widget .tab-content:not(.form) .main-image:hover,
.profile-v2 .box-widget .tab-content:not(.form) .sub-image:hover {
  opacity: 0.7;
  cursor: pointer;
}
.profile-v2 .box-widget .tab-content:not(.form) .sub-photos .sub-image-container:nth-child(-n + 3) {
  padding-top: 2px;
  padding-bottom: 4px;
}
.profile-v2 .box-widget .tab-content:not(.form) .sub-photos .sub-image-container:nth-child(n + 3) {
  padding-top: 4px;
  padding-bottom: 0;
}
.profile-v2 .box-widget .tab-content:not(.form) .sub-photos .sub-image-container:nth-child(odd) {
  padding-right: 4px;
}
.profile-v2 .box-widget .tab-content:not(.form) .sub-photos .sub-image-container:nth-child(even) {
  padding-left: 4px;
}
.profile-v2 .box-widget .tab-content:not(.form) .profile-box {
  border-radius: 4px;
  background-color: #385856;
  color: var(--primary-white);
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.profile-v2 .box-widget .tab-content:not(.form) .profile-box .profile-box-title {
  font-size: 12px;
  font-weight: bold;
}
.profile-v2 .box-widget .tab-content:not(.form) .profile-box .profile-box-value {
  font-size: 42px;
  font-weight: bold;
}
.profile-v2 .table-v2 td {
  font-size: 13px;
}
.profile-v2 .timeline > li {
  /* height: auto; */
}
.profile-v2 .timeline > li > .fa {
  width: 40px;
  height: 40px;
  font-size: 25px;
  top: -4px;
  left: 13px;
  padding-top: 6px;
}
.profile-v2 .timeline > li > .timeline-item {
  background-color: transparent;
  font-size: 14px;
  font-weight: 500;
  color: var(--border-grey);
}
.profile-v2 .timeline > li > .timeline-item > .timeline-header {
  border-bottom: none;
  font-size: 12px;
}
.profile-v2 .timeline > li > .timeline-item > .time {
  padding: 8px;
}
.profile-v2 .timeline > li > .timeline-item > .time > .fa {
  margin-right: 8px;
  font-size: 16px;
}
.profile-v2 .timeline > li > .timeline-item > .tools .btn-custom-action .fa {
  color: var(--gunmetal);
  font-size: 6px;
}
.profile-v2 .box-body.bg-pedigree-gray {
  border-radius: 8px;
}
.profile-v2 .inner-addon .fa {
  color: #cccecf;
}
.profile-v2 .ledger-filter-description {
  height: 34px;
}
.profile-v2 .ledger-filter-description > span {
  padding: 0 20px;
}
.profile-v2 .profile-actions {
  margin-bottom: 10px;
}
.profile-v2 .profile-actions .btn.dropdown-toggle {
  background-color: var(--primary-white);
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 500;
  border-color: var(--border-light-grey);
}
.profile-v2 .profile-actions .btn.dropdown-toggle span:not(.no-extra-margin) {
  margin-right: 15px;
}
.profile-v2 .profile-actions .btn.dropdown-toggle span.sort-title {
  font-size: 14px;
  font-weight: normal;
}
.profile-v2 .profile-actions .btn.dropdown-toggle .fa:not(.sort-icon) {
  font-size: 25px;
}
.profile-v2 .profile-actions .btn.dropdown-toggle .fa.sort-icon {
  margin-right: 10px;
  line-height: 25px;
}
.profile-v2 .profile-actions .dropdown-menu:not(.sub-dropdown) {
  position: absolute;
  right: 0;
  left: auto;
}
.profile-v2 .profile-actions .sub-dropdown {
  right: auto;
  left: -101%;
  padding: 0px;
}
.profile-v2 .no-extra-margin {
  margin: 0;
}
.profile-v2 .profile-tab-title {
  font-size: 13px;
  font-weight: bold;
}
.profile-v2 .table-dropdown .fa {
  font-size: 25px;
  margin-right: 10px;
  color: var(--light-grey);
}
.profile-v2 .litter-kit-expand-toggle[aria-expanded="true"] .fa-angle-down,
.profile-v2 .litter-kit-expand-toggle[aria-expanded="false"] .fa-angle-up,
.profile-v2 .cage-kit-expand-toggle[aria-expanded="true"] .fa-angle-down,
.profile-v2 .cage-kit-expand-toggle[aria-expanded="false"] .fa-angle-up {
  display: none;
}
.profile-v2 .btn.profile-tab-add {
  padding: 5px 15px;
  -webkit-text-stroke: 2px #00a65a;
}
.profile-v2 .btn.profile-tab-add .fa.fa-plus {
  font-size: 25px;
  line-height: 23px;
}
.profile-v2 .btn.profile-tab-add:hover {
  -webkit-text-stroke: 2px #008d4c;
}
.profile-v2 .table-filters .btn.profile-tab-add {
  padding: 9px 15px;
  border-radius: 8px;
}
.profile-v2 .table-filters .btn.profile-tab-add .fa.fa-plus {
  line-height: 20px;
}
.profile-v2 .table-filters .btn.tile-view-switcher {
  background-color: var(--border-light-grey);
  margin-left: 20px;
}
.profile-v2 .table-v2 tbody tr.litter-container.active td {
  border-top: 1px solid #bbddd0 !important;
  background: #f0faf1;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: none !important;
}
.profile-v2 .table-v2 tbody tr.litter-container.active td:first-child {
  border-left: 1px solid #bbddd0 !important;
}
.profile-v2 .table-v2 tbody tr.litter-container.active td:last-child {
  border-right: 1px solid #bbddd0 !important;
}
.profile-v2 .table-v2 tbody tr.litter-kits-container > td {
  padding: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  border: 1px solid #bbddd0 !important;
  background: #f0faf1;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top: none !important;
}
.profile-v2 .table-v2 tbody tr.litter-kits-container .table-v2 {
  background-color: transparent;
  border-spacing: 0px;
}
.profile-v2 .table-v2 tbody tr.litter-kits-container .table-v2 thead {
  background-color: #bbddd0;
}
.profile-v2 .table-v2 tbody tr.litter-kits-container .table-v2 thead th {
  padding: 10px 0px !important;
}
.profile-v2 .table-v2 tbody tr.litter-kits-container .table-v2 tbody td {
  padding: 10px 0px !important;
  border-top: none !important;
  border-bottom: none !important;
}
.profile-v2 .table-v2 tbody tr.litter-kits-container .table-v2 tbody td:first-child {
  padding-left: 1.5% !important;
}
.profile-v2 .table-v2 tbody tr.litter-kits-container .table-v2 tbody td:last-child {
  padding-right: 1.5% !important;
}
.profile-v2 .table-v2 tbody tr.litter-kits-container .table-v2 tbody td:first-child,
.profile-v2 .table-v2 tbody tr.litter-kits-container .table-v2 thead th:first-child {
  border-left: 1px solid var(--table-light-grey);
}
.profile-v2 .w-20 {
  width: 20%;
}
.profile-v2 .stats-container.no-butcher .stats-item {
  width: 25%;
}
.profile-v2 .stats-container {
  justify-content: center;
}
.profile-v2 .stats-container .stats-item {
  width: 20%;
  padding: 0 5px;
  float: left;
}
.profile-v2 .stats-container .stats-item .stats-box {
  position: relative;
  border-radius: 8px;
  color: var(--primary-white);
  height: 165px;
  padding: 20px 25px;
}
.profile-v2 .stats-container .stats-item .stats-box .stats-box-title {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}
.profile-v2 .stats-container .stats-item .stats-box .stats-box-value {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 5px;
}
.profile-v2 .stats-container .stats-item .stats-box .stats-box-description {
  font-size: 14px;
  font-weight: normal;
}
.profile-v2 .stats-container .stats-item .stats-box.survival {
  background-color: #029856;
}
.profile-v2 .stats-container .stats-item .stats-box.gestation {
  background-color: #d81a60;
}
.profile-v2 .stats-container .stats-item .stats-box.kits {
  background-color: #fb902b;
}
.profile-v2 .stats-container .stats-item .stats-box.butcher {
  background-color: #e03f36;
}
.profile-v2 .stats-container .stats-item .stats-box.growth {
  background-color: #030aac;
}
.profile-v2 .stats-container .stats-item .stats-box.general {
  background-color: var(--primary-white);
  color: var(--gunmetal);
  min-height: 140px;
  height: auto;
}
.profile-v2 .stats-container .stats-item .stats-box.general .flex-center {
  justify-content: center;
}
.profile-v2 .stats-container .stats-item .stats-box.general .stats-icon {
  position: relative;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
}
.profile-v2 .stats-container .stats-item .stats-box.general .stats-icon .fa {
  font-size: 32px;
  line-height: 32px;
  color: var(--primary-white);
  opacity: 1;
}
.profile-v2 .stats-container .stats-item .stats-box.general .stats-icon.primary {
  background-color: #1e98bc;
}
.profile-v2 .stats-container .stats-item .stats-box.general .stats-icon.success {
  background-color: #21a55a;
}
.profile-v2 .stats-container .stats-item .stats-box.general .stats-icon.danger {
  background-color: #d61e5e;
}
.profile-v2 .stats-container .stats-item .stats-box.general .stats-box-title {
  font-size: 20px;
  font-weight: 500;
}
.profile-v2 .stats-container .stats-item .stats-box.general .stats-box-description {
  font-size: 14px;
  font-weight: normal;
  color: #465156;
}
.profile-v2 .stats-container .stats-item .stats-icon {
  position: absolute;
  bottom: 15px;
  right: 15px;
}
.profile-v2 .stats-container .stats-item .stats-icon .fa {
  font-size: 75px;
  line-height: 50px;
  color: black;
  opacity: 0.2;
}
.profile-v2 .production-graph-container {
  padding: 20px;
  border: 1px solid var(--lighter-grey);
  margin: 20px 0;
  box-shadow: 0px 0px 10px var(--lighter-grey);
}
.profile-v2 .production-graph-container .production-graph {
  padding: 0 40px;
}
.profile-v2 .production-graph-container.rounded {
  background-color: var(--primary-white);
  border-radius: 8px;
}
.profile-v2 .chart-legend.inline li {
  display: inline-block;
  margin: 0 5px;
}
.profile-v2 .report-title {
  font-size: 22px;
  font-weight: 500;
}
.profile-v2 .production-actions .btn {
  font-size: 12px;
  font-weight: 500;
}
.profile-v2 .production-actions .btn.active {
  background-color: var(--border-light-grey);
  border-color: var(--border-light-grey);
}
.profile-v2 .empty-reports {
  height: 200px;
  justify-content: center;
}
.profile-v2 .kit-status-graph {
  padding-top: 20px;
  margin-bottom: 20px;
}
.profile-v2 .kit-status-graph .production-graph-container {
  margin-top: 0px;
  height: 100%;
}
.profile-v2 .kit-status-graph .production-graph-container .main-content {
  height: 90%;
}
.profile-v2 .kit-status-graph .production-graph-container .main-content .main-chart {
  width: 65%;
}
.profile-v2 .kit-status-graph .production-graph-container .main-content .legend {
  width: 35%;
  font-size: 13px;
  padding-left: 5px;
}
.profile-v2 .kit-status-graph .production-graph-container .main-content .legend li {
  margin-bottom: 5px;
}
.profile-v2 .kit-status-graph .production-graph-container .main-content .legend li .fa {
  margin-right: 5px;
}
.profile-v2 .kit-status-graph .item-active {
  color: var(--primary-green);
}
.profile-v2 .kit-status-graph .item-breeder {
  color: var(--primary-blue);
}
.profile-v2 .kit-status-graph .item-butchered {
  color: var(--primary-red);
}
.profile-v2 .kit-status-graph .item-sold {
  color: var(--primary-teal);
}
.profile-v2 .kit-status-graph .item-died {
  color: var(--primary-crimson);
}
.profile-v2 .kit-status-graph .item-culled {
  color: var(--primary-purple);
}
.profile-v2 .kit-status-graph .item-archived {
  color: var(--primary-yellow);
}
@media (max-width: 1366px) {
  .profile-v2 .stats-container .stats-item .stats-box {
    padding: 20px 10px;
  }
  .profile-v2 .stats-container .stats-item .stats-box .stats-box-value {
    font-size: 20px;
  }
  .profile-v2 .stats-container .stats-item .stats-box .stats-box-description {
    font-size: 13px;
  }
  .profile-v2 .stats-container .stats-item .stats-box .stats-box-value {
    margin-bottom: 0px;
  }
}
@media (max-width: 768px) {
  .profile-v2 .widget-user-2 .widget-user-header {
    padding: 10px !important;
  }
  .profile-v2 .widget-user-2 .widget-user-username,
  .profile-v2 .widget-user-2 .widget-user-desc {
    margin-left: 15px;
  }
  .profile-v2 .content-header {
    padding-top: 10px !important;
  }
  .profile-v2 .box-widget .tab-content .profile-box {
    height: 150px;
  }
  .profile-v2 .box.box-widget.main-profile {
    padding-bottom: 50px;
  }
  .profile-v2 .ledger-filter-description {
    margin-top: 10px;
    justify-content: center;
  }
  .profile-v2 ul.profile-tabs {
    list-style: none;
    white-space: nowrap;
    overflow: auto;
  }
  .profile-v2 ul.profile-tabs li {
    display: inline-block;
    margin-left: 0px;
  }
}
@media (max-width: 992px) {
  .profile-v2 .stats-container .stats-item {
    width: 50% !important;
    padding: 5px 5px;
  }
  .profile-v2 .kit-status-graph {
    padding-left: 0px;
    padding-right: 0px;
  }
  .profile-v2 .production-graph-container {
    overflow: auto;
  }
  .profile-v2 .production-graph-container .header {
    overflow: scroll;
    white-space: nowrap;
  }
  .profile-v2 .production-graph-container .header > div {
    display: inline-block;
  }
  .profile-v2 .production-graph-container .main-production-content {
    overflow: scroll;
    white-space: nowrap;
    /* > div {
          min-width: 800px;
        } */
  }
}
@media (max-width: 500px) {
  .profile-v2 .stats-container .stats-item {
    width: 100% !important;
    padding: 5px 5px;
  }
  .profile-v2 #timeline > .row > .col-md-12 {
    padding: 0 !important;
  }
  .profile-v2 .widget-user-username .sub-name {
    display: block;
  }
  .profile-v2 .widget-user-header.flex-center {
    flex-direction: column;
  }
  .profile-v2 .widget-user-header.flex-center .litter-quick-data {
    margin-left: 0px;
    margin-top: 15px;
    border-left: none;
    border-top: 2px solid var(--primary-white);
    padding-top: 10px;
    display: flex;
    align-self: center;
  }
  .profile-v2 .widget-user-header.flex-center .litter-actions.pull-right {
    position: absolute;
    top: 8px;
    right: 8px;
  }
}
@media (max-width: 1100px) {
  .profile-v2 .parent-data-container.flex-center {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
  }
  .profile-v2 .parent-data-container.flex-center > div {
    margin-top: 10px;
  }
  .profile-v2 .parent-data-container.flex-center > div .widget-user-image {
    margin-left: 0px !important;
  }
  .profile-v2 .litter-quick-data {
    margin-left: 10px;
  }
}
@media (max-width: 1280px) {
  .profile-v2 .parent-data-container.flex-center .widget-user-username {
    font-size: 24px !important;
    line-height: normal;
  }
  .profile-v2 .parent-data-container.flex-center .widget-user-username .sub-name {
    display: block;
  }
  .profile-v2 .litter-quick-data {
    align-self: center !important;
  }
}
