.profile-v2 {
  .box.box-widget {
    border-radius: 8px;
    border: 2px solid transparent;
  }

  .breeder-sex-icon {
    background-color: var(--primary-white);
    margin-left: auto;
  }

  .bg-maroon-v1 .breeder-sex-icon {
    color: #d81a61;
  }

  .bg-aqua-v1 .breeder-sex-icon {
    color: #0092bb;
  }

  .widget-user-2 .widget-user-header:not(.pedigree-image) {
    padding: 10px 30px;
  }

  .widget-user-image:not(.pedigree-v2-image) .img-circle:not(.pedigree-card-2) {
    margin-left: 0px;
    border: 2px solid;
  }

  .widget-user-2 .widget-user-username:not(.pedigree-user-username),
  .widget-user-2 .widget-user-desc:not(.pedigree-user-desc) {
    margin-left: 25px;
  }

  .widget-user-desc:not(.pedigree-user-desc) {
    font-size: 13px;
    font-weight: 500;
  }
  .widget-user-username:not(.pedigree-user-username) {
    font-size: 32px !important;
    line-height: 32px;
    font-weight: 500;

    .sub-name {
      font-size: 14px;
      font-weight: normal;
    }
  }

  .box-widget {
    .nav-pills:not(.pedigree-settings, .form) {
      padding: 25px 25px 10px 25px;

      .placeholder {
        pointer-events: none;
      }
    }

    .tab-content:not(.form) {
      padding: 0 25px;

      .profile-content {
        border-radius: 4px;
        border: solid 1px var(--generic-bg);
        padding: 10px;

        .profile-content-item {
          padding: 10px;
          border-bottom: solid 1px var(--generic-bg);

          .profile-content-title {
            font-size: 14px;
            font-weight: 500;
            color: var(--gunmetal);
          }

          .profile-content-value {
            font-size: 12px;
            font-weight: 500;
            color: var(--border-grey);
            margin-left: auto;
          }

          .profile-parents {
            width: 100%;
            padding: 20px 0;
            display: inline-flex;
            text-align: center;

            .profile-father,
            .profile-mother {
              width: 50%;
              cursor: not-allowed;
            }

            .profile-father.available,
            .profile-mother.available {
              cursor: pointer;
            }

            .profile-father.available:hover,
            .profile-mother.available:hover {
              opacity: 0.7;
            }

            .profile-father {
              border-right: solid 1px var(--footer-grey);
            }
          }
        }

        .profile-content-item.is-parent {
          padding: 0 10px;
          border-bottom: none;
          background-color: var(--generic-bg);
        }
      }

      .main-image,
      .sub-image {
        object-fit: cover;
        width: 100%;
      }

      .main-image:hover,
      .sub-image:hover {
        opacity: 0.7;
        cursor: pointer;
      }

      .sub-photos .sub-image-container:nth-child(-n + 3) {
        padding-top: 2px;
        padding-bottom: 4px;
      }
      .sub-photos .sub-image-container:nth-child(n + 3) {
        padding-top: 4px;
        padding-bottom: 0;
      }
      .sub-photos .sub-image-container:nth-child(odd) {
        padding-right: 4px;
      }
      .sub-photos .sub-image-container:nth-child(even) {
        padding-left: 4px;
      }

      .profile-box {
        border-radius: 4px;
        background-color: #385856;
        color: var(--primary-white);
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        .profile-box-title {
          font-size: 12px;
          font-weight: bold;
        }

        .profile-box-value {
          font-size: 42px;
          font-weight: bold;
        }
      }
    }
  }

  .table-v2 td {
    font-size: 13px;
  }

  .timeline {
    > li {
      /* height: auto; */

      > .fa {
        width: 40px;
        height: 40px;
        font-size: 25px;
        top: -4px;
        left: 13px;
        padding-top: 6px;
      }

      > .timeline-item {
        background-color: transparent;
        font-size: 14px;
        font-weight: 500;
        color: var(--border-grey);

        > .timeline-header {
          border-bottom: none;
          font-size: 12px;
        }

        > .time {
          padding: 8px;
          > .fa {
            margin-right: 8px;
            font-size: 16px;
          }
        }

        > .tools .btn-custom-action .fa {
          color: var(--gunmetal);
          font-size: 6px;
        }
      }
    }
  }

  .box-body.bg-pedigree-gray {
    border-radius: 8px;
  }

  .inner-addon .fa {
    color: #cccecf;
  }

  .ledger-filter-description {
    height: 34px;

    > span {
      padding: 0 20px;
    }
  }

  .profile-actions {
    margin-bottom: 10px;

    .btn.dropdown-toggle {
      background-color: var(--primary-white);
      padding: 5px 10px;
      font-size: 16px;
      font-weight: 500;
      border-color: var(--border-light-grey);

      span:not(.no-extra-margin) {
        margin-right: 15px;
      }
      span.sort-title {
        font-size: 14px;
        font-weight: normal;
      }

      .fa:not(.sort-icon) {
        font-size: 25px;
      }

      .fa.sort-icon {
        margin-right: 10px;
        line-height: 25px;
      }
    }

    .dropdown-menu:not(.sub-dropdown) {
      position: absolute;
      right: 0;
      left: auto;
    }

    .sub-dropdown {
      right: auto;
      left: -101%;
      padding: 0px;
    }
  }

  .no-extra-margin {
    margin: 0;
  }

  .profile-tab-title {
    font-size: 13px;
    font-weight: bold;
  }

  .table-dropdown {
    .fa {
      font-size: 25px;
      margin-right: 10px;
      color: var(--light-grey);
    }
  }

  .litter-kit-expand-toggle[aria-expanded="true"] .fa-angle-down,
  .litter-kit-expand-toggle[aria-expanded="false"] .fa-angle-up,
  .cage-kit-expand-toggle[aria-expanded="true"] .fa-angle-down,
  .cage-kit-expand-toggle[aria-expanded="false"] .fa-angle-up {
    display: none;
  }

  .btn.profile-tab-add {
    padding: 5px 15px;
    -webkit-text-stroke: 2px #00a65a;

    .fa.fa-plus {
      font-size: 25px;
      line-height: 23px;
    }
  }

  .btn.profile-tab-add:hover {
    -webkit-text-stroke: 2px #008d4c;
  }

  .table-filters {
    .btn.profile-tab-add {
      padding: 9px 15px;
      border-radius: 8px;

      .fa.fa-plus {
        line-height: 20px;
      }
    }

    .btn.tile-view-switcher {
      background-color: var(--border-light-grey);
      margin-left: 20px;
    }
  }

  .table-v2 tbody tr.litter-container.active {
    td {
      border-top: 1px solid #bbddd0 !important;
      background: #f0faf1;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border-bottom: none !important;
    }

    td:first-child {
      border-left: 1px solid #bbddd0 !important;
    }
    td:last-child {
      border-right: 1px solid #bbddd0 !important;
    }
  }

  .table-v2 tbody tr.litter-kits-container {
    > td {
      padding: 0 !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      border: 1px solid #bbddd0 !important;
      background: #f0faf1;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      border-top: none !important;

      // div.collapse {
      //   margin-top: -8px;
      // }
    }

    .table-v2 {
      background-color: transparent;
      border-spacing: 0px;

      thead {
        background-color: #bbddd0;

        th {
          padding: 10px 0px !important;
        }
      }
      tbody {
        td {
          padding: 10px 0px !important;
          border-top: none !important;
          border-bottom: none !important;
        }
        td:first-child {
          padding-left: 1.5% !important;
        }
        td:last-child {
          padding-right: 1.5% !important;
        }
      }
    }

    .table-v2 tbody td:first-child,
    .table-v2 thead th:first-child {
      border-left: 1px solid var(--table-light-grey);
    }
  }

  .w-20 {
    width: 20%;
  }

  .stats-container.no-butcher {
    .stats-item {
      width: 25%;
    }
  }

  .stats-container {
    justify-content: center;

    .stats-item {
      width: 20%;
      padding: 0 5px;
      float: left;

      .stats-box {
        position: relative;
        border-radius: 8px;
        color: var(--primary-white);
        height: 165px;
        padding: 20px 25px;

        .stats-box-title {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 5px;
        }
        .stats-box-value {
          font-size: 26px;
          font-weight: bold;
          margin-bottom: 5px;
        }
        .stats-box-description {
          font-size: 14px;
          font-weight: normal;
        }
      }

      .stats-box.survival {
        background-color: #029856;
      }
      .stats-box.gestation {
        background-color: #d81a60;
      }
      .stats-box.kits {
        background-color: #fb902b;
      }
      .stats-box.butcher {
        background-color: #e03f36;
      }
      .stats-box.growth {
        background-color: #030aac;
      }
      .stats-box.general {
        background-color: var(--primary-white);
        color: var(--gunmetal);
        min-height: 140px;
        height: auto;

        .flex-center {
          justify-content: center;
        }

        .stats-icon {
          position: relative;
          bottom: 0;
          right: 0;
          border-radius: 50%;
          font-size: 22px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 65px;
          height: 65px;

          .fa {
            font-size: 32px;
            line-height: 32px;
            color: var(--primary-white);
            opacity: 1;
          }
        }

        .stats-icon.primary {
          background-color: #1e98bc;
        }
        .stats-icon.success {
          background-color: #21a55a;
        }
        .stats-icon.danger {
          background-color: #d61e5e;
        }

        .stats-box-title {
          font-size: 20px;
          font-weight: 500;
        }

        .stats-box-description {
          font-size: 14px;
          font-weight: normal;
          color: #465156;
        }
      }

      .stats-icon {
        position: absolute;
        bottom: 15px;
        right: 15px;

        .fa {
          font-size: 75px;
          line-height: 50px;
          color: black;
          opacity: 0.2;
        }
      }
    }
  }

  .production-graph-container {
    padding: 20px;
    border: 1px solid var(--lighter-grey);
    margin: 20px 0;
    box-shadow: 0px 0px 10px var(--lighter-grey);

    .production-graph {
      padding: 0 40px;
    }
  }

  .production-graph-container.rounded {
    background-color: var(--primary-white);
    border-radius: 8px;
  }

  .chart-legend.inline li {
    display: inline-block;
    margin: 0 5px;
  }

  .report-title {
    font-size: 22px;
    font-weight: 500;
  }

  .production-actions .btn {
    font-size: 12px;
    font-weight: 500;
  }

  .production-actions .btn.active {
    background-color: var(--border-light-grey);
    border-color: var(--border-light-grey);
  }

  .empty-reports {
    height: 200px;
    justify-content: center;
  }

  .kit-status-graph {
    padding-top: 20px;
    margin-bottom: 20px;

    .production-graph-container {
      margin-top: 0px;
      height: 100%;

      .main-content {
        height: 90%;

        .main-chart {
          width: 65%;
        }

        .legend {
          width: 35%;
          font-size: 13px;
          padding-left: 5px;

          li {
            margin-bottom: 5px;

            .fa {
              margin-right: 5px;
            }
          }
        }
      }
    }

    .item-active {
      color: var(--primary-green);
    }
    .item-breeder {
      color: var(--primary-blue);
    }
    .item-butchered {
      color: var(--primary-red);
    }
    .item-sold {
      color: var(--primary-teal);
    }
    .item-died {
      color: var(--primary-crimson);
    }
    .item-culled {
      color: var(--primary-purple);
    }
    .item-archived {
      color: var(--primary-yellow);
    }
  }

  @media (max-width: 1366px) {
    .stats-container .stats-item .stats-box {
      padding: 20px 10px;

      .stats-box-value {
        font-size: 20px;
      }
      .stats-box-description {
        font-size: 13px;
      }
      .stats-box-value {
        margin-bottom: 0px;
      }
    }
  }

  @media (max-width: 768px) {
    .widget-user-2 .widget-user-header {
      padding: 10px !important;
    }
    .widget-user-2 .widget-user-username,
    .widget-user-2 .widget-user-desc {
      margin-left: 15px;
    }

    .content-header {
      padding-top: 10px !important;
    }

    .box-widget .tab-content .profile-box {
      height: 150px;
    }

    .box.box-widget.main-profile {
      padding-bottom: 50px;
    }

    .ledger-filter-description {
      margin-top: 10px;
      justify-content: center;
    }

    ul.profile-tabs {
      list-style: none;
      white-space: nowrap;
      overflow: auto;

      li {
        display: inline-block;
        margin-left: 0px;
      }
    }
  }

  @media (max-width: 992px) {
    .stats-container {
      .stats-item {
        width: 50% !important;
        padding: 5px 5px;
      }
    }

    .kit-status-graph {
      padding-left: 0px;
      padding-right: 0px;
    }

    .production-graph-container {
      overflow: auto;

      .header {
        overflow: scroll;
        white-space: nowrap;

        > div {
          display: inline-block;
        }
      }
      .main-production-content {
        overflow: scroll;
        white-space: nowrap;

        /* > div {
          min-width: 800px;
        } */
      }
    }
  }

  @media (max-width: 500px) {
    .stats-container {
      .stats-item {
        width: 100% !important;
        padding: 5px 5px;
      }
    }

    #timeline > .row > .col-md-12 {
      padding: 0 !important;
    }

    .widget-user-username {
      .sub-name {
        display: block;
      }
    }

    .widget-user-header.flex-center {
      flex-direction: column;

      .litter-quick-data {
        margin-left: 0px;
        margin-top: 15px;
        border-left: none;
        border-top: 2px solid var(--primary-white);
        padding-top: 10px;
        display: flex;
        align-self: center;
      }

      .litter-actions.pull-right {
        position: absolute;
        top: 8px;
        right: 8px;
      }
    }
  }

  @media (max-width: 1100px) {
    .parent-data-container.flex-center {
      flex-direction: column;
      align-items: flex-start;
      padding: 15px;

      > div {
        margin-top: 10px;

        .widget-user-image {
          margin-left: 0px !important;
        }
      }
    }

    .litter-quick-data {
      margin-left: 10px;
    }
  }

  @media (max-width: 1280px) {
    .parent-data-container.flex-center {
      .widget-user-username {
        font-size: 24px !important;
        line-height: normal;

        .sub-name {
          display: block;
        }
      }
    }

    .litter-quick-data {
      align-self: center !important;
    }
  }
}
