.breeders-v2 {
  font-family: "Roboto", sans-serif !important;
}
.breeders-v2 .table-responsive {
  border: none !important;
}
.breeders-v2 .breeder.widget-user-image > img {
  width: 85px;
}
.breeders-v2 .breeder.widget-user-image {
  margin-left: -45px;
  top: 85px;
  z-index: 10;
}
.breeders-v2 .btn-custom-action {
  background-color: transparent;
  border: none;
}
.breeders-v2 .tile-view .btn-custom-action .fa {
  color: var(--primary-white);
  font-size: 5px;
}
.breeders-v2 .widget-user:not(.pedigree-v2) {
  margin-bottom: 30px;
}
.breeders-v2 .widget-user:not(.pedigree-v2) .widget-user-header {
  height: 125px;
}
.breeders-v2 .widget-user:not(.pedigree-v2) .widget-user-header.transfer-header {
  position: relative;
}
.breeders-v2 .widget-user:not(.pedigree-v2) .widget-user-username {
  font-size: 22px;
  font-weight: bold;
  text-overflow: ellipsis;
}
.breeders-v2 .widget-user:not(.pedigree-v2) .widget-user-desc {
  font-size: 14px;
  font-weight: 500;
  margin: 1px 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.breeders-v2 .widget-user .box-footer:not(.revert-pt) {
  padding-top: 10px;
}
.breeders-v2 .color-doe .description-header {
  color: #d81a61;
}
.breeders-v2 .color-buck .description-header {
  color: #0090b9;
}
.breeders-v2 .description-header {
  font-size: 30px;
  font-weight: bold;
}
.breeders-v2 .description-header .unit {
  font-size: 18px;
}
.breeders-v2 .description-header .unit.align-top {
  vertical-align: top;
}
.breeders-v2 .description-header .unit.align-baseline {
  vertical-align: baseline;
}
.breeders-v2 .description-text {
  font-size: 11px;
  font-weight: bold;
  color: #333333;
}
.breeders-v2 .content-header > .box-header {
  padding: 15px;
  background-color: var(--primary-white);
}
.breeders-v2 .box-header > .btn {
  margin: 0 3px;
}
.breeders-v2 .breedlist-content.list-view {
  background: var(--primary-white);
  padding: 40px 20px;
  border-radius: 8px;
}
.breeders-v2 .list-view.table-container {
  margin: 0 !important;
}
.breeders-v2 .list-view.table-container {
  margin: 0 !important;
}
.breeders-v2 .list-view.table-container .table-header > div {
  display: inline;
}
.breeders-v2 .content-header {
  padding: 0 !important;
}
.breeders-v2 .content-header a.btn {
  color: #465156;
}
.breeders-v2 .content-header .btn span {
  font-size: 15px;
  font-weight: 500;
}
.breeders-v2 .content-header .btn .fa {
  font-size: 18px;
}
.breeders-v2 .content-header .header-title {
  font-size: 16px;
  font-weight: bold;
  vertical-align: middle;
}
.breeders-v2 .layout-switch-button {
  margin-left: auto;
}
.breeders-v2 .layout-switch-button .btn .header-title {
  margin-left: 12px;
}
.breeders-v2 .layout-switch-button .btn .fa {
  font-size: 23px;
  color: #465156;
}
.breeders-v2 .layout-switch-button .btn {
  padding-left: 0;
  padding-right: 0;
}
.breeders-v2 .rounded-circle {
  border-radius: 50%;
}
.breeders-v2 .cage-card-submenu-dropdown {
  border-color: transparent;
}
.breeders-v2 .cage-card-submenu-dropdown .fa {
  font-size: 14px !important;
}
.breeders-v2 .breedlist-table .circular-icon {
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.breeders-v2 .breedlist-table .circular-icon.fa-venus {
  background-color: #d81a61;
}
.breeders-v2 .breedlist-table .circular-icon.fa-mars {
  background-color: #0092bb;
}
.breeders-v2 .table-v2 td {
  font-size: 14px;
}
.breeders-v2 .table-v2 td a.breeder-category {
  color: #03985d;
  text-decoration: underline;
}
.breeders-v2 .pagination .active a {
  color: var(--primary-white);
  background-color: #03985d;
}
.breeders-v2 .table-header {
  margin-bottom: 25px;
}
.breeders-v2 .table-title {
  margin-right: 30px;
  font-size: 20px;
  font-weight: 900;
}
.breeders-v2 .btn.breeder-menu {
  background-color: var(--primary-white);
  padding: 9px 15px;
  font-size: 14px;
  font-weight: 500;
  color: #222222;
  border-radius: 8px;
  margin: 0px 2px;
}
.breeders-v2 .btn.breeder-menu.active {
  background-color: #465156;
  color: var(--primary-white);
}
.breeders-v2 .table-actions .btn.breeder-menu {
  border-radius: 4px;
  padding: 9px 7px;
  min-width: 39.5px;
  justify-content: center;
}
.breeders-v2 .table-actions .dropdown-menu {
  min-width: 50px;
  right: 0;
  left: auto;
  border-radius: 2px;
  box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.24), 0 0 16px 0 rgba(0, 0, 0, 0.12);
}
.breeders-v2 .display-actions.dropdown-menu {
  min-width: 200px;
}
.breeders-v2 .display-actions .icheck-group {
  padding: 0px 12px;
}
.breeders-v2 .display-actions .icheck-label {
  padding-left: 25px;
  font-size: 16px;
  font-weight: normal;
  color: #777;
}
.breeders-v2 .main-table-filters {
  padding: 10px 0px;
}
.breeders-v2 .main-table-filters .filter-actions {
  padding-top: 15px;
  margin-top: 10px;
  text-align: right;
  border-top: 1px solid #c5c5c5;
}
.breeders-v2 .main-table-filters .filter-actions .btn {
  padding: 8px 38px;
  border-radius: 100px;
  font-size: 15px;
  font-weight: bold;
}
.breeders-v2 .main-table-filters .filter-actions .cancel-btn {
  border: solid 2px #4c4c4c;
  color: #4c4c4c;
  margin-left: 6px !important;
}
.breeders-v2 .main-table-filters .main-search,
.breeders-v2 .main-table-filters .main-filter:not(.is-header) {
  padding: 12px 25px;
  border-radius: 24px !important;
}
.breeders-v2 .main-table-filters .main-filter {
  color: #465156;
}
.breeders-v2 .main-table-filters .main-filter.btn {
  font-weight: 500;
  font-size: 15px;
}
.breeders-v2 .main-table-filters .input-extension-button {
  float: right;
  margin-right: 17px;
  margin-top: -38px;
  position: relative;
  z-index: 2;
  font-size: 15px;
  color: var(--primary-white);
  background-color: var(--secondary-green) !important;
}
.breeders-v2 .main-table-filters .main-filters {
  box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.06), 0 0 16px 0 rgba(0, 0, 0, 0.12);
  margin-top: 10px;
  color: #4c4c4c;
}
.breeders-v2 .main-table-filters .search-description {
  padding-top: 10px;
  font-size: 13px;
  font-weight: 500;
}
.breeders-v2 .btn-group + .btn-group {
  margin-left: 0px;
}
.breeders-v2 .filter-tags {
  background-color: #465156;
  color: var(--primary-white);
  padding: 5px 10px;
  font-size: 16px;
  font-weight: normal;
  border-radius: 100px;
  margin: 2px;
  display: inline-block;
  white-space: normal;
}
.breeders-v2 .filter-tags .fa {
  cursor: pointer;
  color: #8e979b;
  font-size: 18px;
  margin-left: 5px;
}
.breeders-v2 .filter-tags .fa:hover {
  color: var(--primary-white);
}
.breeders-v2 .extra-action-buttons {
  margin-bottom: 15px;
  margin-top: -15px;
}
.breeders-v2 .extra-action-buttons .btn span {
  font-size: 15px;
  font-weight: 500;
}
.breeders-v2 .extra-action-buttons .btn .fa {
  font-size: 18px;
}
.breeders-v2 .breeder-form-v2 label {
  text-align: left !important;
  margin-bottom: 9px;
}
.breeders-v2 .breeder-form-v2 .sub-label {
  font-size: 14px;
  color: var(--secondary-grey);
}
.breeders-v2 .breeder-form-v2 .breeder-weight-bottom-margin {
  padding-left: 0;
}
.breeders-v2 .breeder-form-v2 .form-group {
  margin-left: 0;
  margin-right: 0;
}
.breeders-v2 .breeder-form-v2 .image-dropzone {
  border: 1px dashed var(--border-grey);
  border-radius: 5px;
  height: 200px;
  background-color: var(--dropzone-bg);
  justify-content: center;
}
.breeders-v2 .pagination {
  margin: 0;
}
.breeders-v2 .pagination a {
  font-weight: 500;
}
.breeders-v2 .pagination li:not(.active) a {
  color: var(--primary-green) !important;
}
.breeders-v2 .pagination-description {
  text-align: center;
  position: absolute;
  top: 7px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  color: var(--primary-green);
  font-weight: 500;
}
.breeders-v2 .row.no-extra-margin {
  margin-left: 0;
  margin-right: 0;
}
.breeders-v2 .pt-15 {
  padding-top: 15px;
}
.breeders-v2 .tile-breeder-image {
  margin-right: 5%;
}
.breeders-v2 .tile-breeder-image > img {
  width: 100px;
  height: auto;
  border: 3px solid var(--primary-white);
  background: var(--primary-white);
}
.breeders-v2 .load-more .btn {
  padding: 6px 12px;
  border-radius: 100px;
  font-size: 14px;
}
.breeders-v2 .wizard-header {
  background-image: url(/media/wizard/breeders/fill-1@2x.png);
  background-size: 450px;
  background-repeat: no-repeat;
  border-radius: 8px;
  margin-bottom: 30px;
  box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.07);
}
.breeders-v2 .wizard-header .header-container {
  padding: 50px 0;
}
.breeders-v2 .wizard-header .title {
  color: var(--primary-green);
  font-size: 35px;
  font-weight: 500;
}
.breeders-v2 .wizard-header .description {
  color: var(--secondary-grey);
  font-size: 18px;
}
.breeders-v2 .wizard-item {
  border-radius: 8px;
  box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.07);
}
.breeders-v2 .wizard-item .header img {
  object-fit: contain;
  width: 100%;
}
.breeders-v2 .wizard-item .body {
  padding: 10px 20px;
  min-height: 250px;
}
.breeders-v2 .wizard-item .body .title {
  font-size: 24px;
  margin-bottom: 10px;
  color: var(--gunmetal);
}
.breeders-v2 .wizard-item .body .description {
  font-size: 16px;
  color: var(--secondary-grey);
}
.breeders-v2 .wizard-item .btn {
  border-radius: 100px;
  font-size: 16px;
  font-weight: 500;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .breeders-v2 .description-header {
    font-size: 18px !important;
  }
  .breeders-v2 .description-header .unit {
    font-size: 14px !important;
  }
  .breeders-v2 .tile-breeder-image > img {
    width: 50px;
  }
  .breeders-v2 .widget-user:not(.pedigree-v2) .widget-user-desc {
    font-size: 13px;
  }
}
@media (max-width: 396px) {
  .breeders-v2 .tile-breeder-image > img {
    width: 70px;
  }
}
@media (max-width: 480px) {
  .breeders-v2 .description-header {
    font-size: 18px !important;
  }
  .breeders-v2 .description-header .unit {
    font-size: 14px !important;
  }
  .breeders-v2 .widget-user .widget-user-header {
    height: 130px;
  }
  .breeders-v2 .widget-user .widget-user-image {
    top: 65px;
  }
  .breeders-v2 .widget-user .box-footer {
    padding-top: 30px !important;
  }
}
@media (max-width: 768px) {
  .breeders-v2 .main-filters {
    width: 100%;
  }
  .breeders-v2 .web-only {
    display: none !important;
  }
  .breeders-v2 .content-header {
    background-color: var(--primary-white);
  }
  .breeders-v2 .breedlist-header > .flex-center {
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
  }
  .breeders-v2 .breedlist-header .breedlist-header-title {
    order: 1;
    margin-right: auto;
  }
  .breeders-v2 .breedlist-header .breedlist-header-actions {
    text-align: center;
    margin-top: 10px;
    width: 100%;
  }
  .breeders-v2 .main-table-filters {
    padding: 0px;
  }
  .breeders-v2 .main-table-filters > div:last-child {
    margin-top: 10px;
  }
  .breeders-v2 .main-table-filters .btn-group {
    width: 100%;
  }
  .breeders-v2 .main-table-filters .btn {
    width: 100%;
  }
  .breeders-v2 .main-table-filters .filter-actions .cancel-btn {
    margin-left: 0px !important;
    margin: 5px 0px;
  }
  .breeders-v2 .main-table-filters .main-filter > .fa {
    margin-left: auto;
  }
  .breeders-v2 .table-header {
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
    padding: 0;
  }
  .breeders-v2 .table-header .table-title {
    order: 1;
    margin-right: auto;
  }
  .breeders-v2 .table-header .table-filters {
    overflow: auto;
    white-space: nowrap;
    text-align: center;
    margin-top: 10px;
    width: 100%;
  }
  .breeders-v2 .table-header .table-actions {
    margin-left: auto;
  }
  .breeders-v2 .table-header .table-actions > span {
    display: none;
  }
  .breeders-v2 .table-header .table-actions .btn {
    font-size: 10px;
  }
  .breeders-v2 .table-header .table-actions .fa {
    font-size: 15px !important;
  }
  .breeders-v2 .table-responsive {
    padding: 0;
  }
  .breeders-v2 .table-responsive > .table-v2 th,
  .breeders-v2 .table-responsive > .table-v2 td {
    padding-right: 1.5% !important;
  }
  .breeders-v2 .table-responsive.freeze-first-child > .table-v2 > thead > tr > th:first-child,
  .breeders-v2 .table-responsive.freeze-first-child > .table-v2 > tbody > tr > td:first-child {
    position: sticky;
    left: 0px;
    z-index: 9;
  }
  .breeders-v2 .table-responsive.freeze-first-child > .table-v2 > thead > tr:not(.litter-container.active) th:first-child {
    background-color: var(--table-light-grey);
  }
  .breeders-v2 .table-responsive.freeze-first-child > .table-v2 > tbody > tr:not(.litter-container.active) td:first-child {
    background-color: var(--primary-white);
  }
  .breeders-v2 .pagination-container {
    text-align: center;
  }
  .breeders-v2 .pagination {
    margin-top: 40px;
  }
}
.breeders-v2 .bred-icon.butchered-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.breeders-v2 .bred-icon.butchered-icon img {
  margin-bottom: 6px;
}
.breeders-v2 .transfer-actions {
  position: absolute;
  right: 15px;
  bottom: 15px;
}
.breeders-v2 .transfer-actions .btn {
  display: block;
  width: 100%;
  padding: 4px 12px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: bold;
  border-color: transparent;
  margin-bottom: 5px;
}
.breeders-v2 .bg-maroon-active .transfer-actions .btn {
  background-color: #f5d8d8;
}
.breeders-v2 .bg-aqua-active .transfer-actions .btn {
  background-color: #d7f7ff;
}
.breeders-v2 .inline-transfer-actions .btn {
  width: 80px;
  padding: 4px 12px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: bold;
  border-color: transparent;
  margin-bottom: 5px;
}
.breeders-v2 .inline-transfer-actions .claim {
  color: var(--primary-white);
  background-color: #03985d;
}
.breeders-v2 .inline-transfer-actions .decline {
  background-color: #f5d8d8;
}
@media (min-width: 768px) {
  .breeders-v2 .main-filters {
    min-width: 650px !important;
  }
  .breeders-v2 .mobile-only {
    display: none !important;
  }
  .breeders-v2 .table-header .table-actions {
    margin-left: auto;
  }
  .breeders-v2 .pagination-container:not(.text-center) {
    text-align: right;
  }
}
@media (min-width: 1000px) and (max-width: 1400px) {
  .breeders-v2 .description-header {
    font-size: 18px !important;
  }
  .breeders-v2 .description-header .unit {
    font-size: 14px !important;
  }
  .breeders-v2 .tile-breeder-content {
    width: 45%;
  }
  .breeders-v2 .tile-breeder-content > .widget-user-username {
    font-size: 18px !important;
  }
  .breeders-v2 .tile-breeder-content > .widget-user-desc {
    width: 130%;
  }
}
@media (min-width: 1400px) and (max-width: 1600px) {
  .breeders-v2 .tile-breeder-content {
    width: 45%;
  }
}
@media (min-width: 1600px) and (max-width: 1800px) {
  .breeders-v2 .tile-breeder-content {
    width: 55%;
  }
}
@media (min-width: 1800px) {
  .breeders-v2 .tile-breeder-content {
    width: 60%;
  }
}
@media (max-width: 530px) {
  .breeders-v2 .tile-view > .col-lg-4 {
    padding: 0 !important;
  }
  .breeders-v2 .tile-breeder-content {
    width: 45%;
  }
  .breeders-v2 .tile-breeder-content > .widget-user-username {
    font-size: 20px !important;
  }
  .breeders-v2 .tile-breeder-content > .widget-user-desc {
    width: 130%;
  }
}
