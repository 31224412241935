.hide-online {
  display: none !important;
}

.online-status {
  z-index: 5000;
  position: absolute;
  left: 40%;
  top: 0;
  padding: .2rem 1rem;
  background-color: green;
  color: #fff;
  text-align: center;

  span.on {
    display: inline;
  }

  span.off {
    display: none;
  }

}


body.offline {

  .online-status {
    background-color: red !important;
  }

  span.on {
    display: none;
  }

  span.off {
    display: inline;
  }

  .hide-online {
    display: block !important;
  }

}

.pricing {
  & > .title {
    font-size: 28px;
    font-weight: 700;
    color: #3c3950;
    text-align: center;
  }
}

.pr-tabs-container {
  .pr-tabs-list {
    margin-bottom: 60px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0;

    .pr-tab {
      margin-top: 1px;
      margin-bottom: 0;
      margin-left: 1px;
      margin-right: 0;
      display: inline-block;
      vertical-align: bottom;
      list-style: none;
      &.pr_active {
        a {
          background-color: #ffffff;
        }
      }

      a {
        padding: 12px 25px;
        display: block;
        border: solid transparent;
        position: relative;
        font-size: 16px;
        border-color: #b6c6c9;
        background-color: #f4f6f7;
        color: #5f727f;
        line-height: 18px;
        text-transform: uppercase;
        transition: background .2s ease-in-out,color .2s ease-in-out;
        -webkit-box-shadow: none;
        border-radius: 3px;
        border-width: 1px;
        box-shadow: none;
      }

     .pr-title-text {
       display: inline-block;
       vertical-align: middle;
     }
    }
  }
}

.pricing-price {
  transition-duration: initial;
}

.pricing-table-style-1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: 5px;
  margin-right: 5px;
}

.pricing-row, .pricing-price-row, .pricing-title-row {
  text-align: center;
}

.pricing-table-style-8 .pricing-price, .pricing-table-style-7 .pricing-price, .pricing-table-style-4 .pricing-price-row, .pricing-table-style-3 .pricing-price-row, .pricing-table-style-2 .pricing-price, .pricing-table-style-1 .pricing-price-row {
  background-color: transparent !important;
  background-image: none !important;
}
.pricing-table-style-1,
.pricing-row.strike {
  color: #99a9b5;
}

.pricing-footer .gem-button {
  padding-left: 15px;
  padding-right: 30px;

  @media (max-width: 1700px) {
    padding-left: 7px;
    padding-right: 16px;
  }

}

.pricing-footer .gem-button i {
  padding-right: 10px;
}

.mac-image {
  z-index: 1;
  overflow: visible !important;
}
.gem-button-position-center {
  text-align: center;
  display: block;
}
.gem-print-icon.gem-icon-pack-elegant {
  display: inline-block;
  font-family: 'ElegantIcons';
  font-feature-settings: normal;
  font-kerning: auto;
  font-language-override: normal;
  font-size: inherit;
  font-size-adjust: none;
  font-stretch: normal;
  font-style: normal;
  font-synthesis: weight style;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-rendering: auto;
  text-align: center;
  vertical-align: middle;
  font-size: 16px;
}
.gem-button i.gem-print-icon {
  line-height: inherit;
  vertical-align: top;
  height: 100%;
  line-height: inherit;
}
.pricing-footer .gem-button i {
  padding-right: 10px;
}
.gem-button, .gem-pagination a {
  font-weight: 700;
}
.gem-button {
  position: relative;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  border: 0 none;
  vertical-align: middle;
  height: 40px;
  line-height: 40px;
  padding: 0 17px;
  font-size: 14px;
  margin: 25px;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 3px;

  .fa-stack {
    margin-right: -11px;
  }
}
.gem-button-size-medium, input.gem-button-size-medium {
  height: 50px;
  line-height: 50px;
  padding: 0 25px;
  font-size: 17px;
  margin: 30px;

  @media (max-width: 1240px) {
    font-size: 14px;
  }
}
.gem-button-icon-position-left.gem-button-size-medium .gem-print-icon {
  margin-right: 9px;
}
.pricing-table-style-1 .pricing-price {
  padding: 29px 10px 29px 10px;
}
.gem-button-position-center .gem-button, .gem-button-position-fullwidth .gem-button {
  margin-left: 0;
  margin-right: 0;
}
.pricing-table-style-1 .pricing-price-title-wrapper {
  padding-top: 33px;
  padding-bottom: 33px;
}
.pricing-table-style-1 .pricing-column-wrapper .pricing-price{
  overflow: hidden;
}
.pricing-table-style-1 .pricing-row {
  padding-top: 12px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dfe5e8;
}

.pricing-table-style-1 .pricing_row_title {
  font-size: 24px;
  font-weight: 300;
  padding-bottom: 5px;
  color: #3c3950;
}
.pricing-table-style-1 .pricing_row_subtitle {
  font-size: 14px;
  color: #99a9b5;
}
.pricing-column-top-choice{
  position: absolute;
}
.pricing-table-style-1 .pricing-column {
  background-color: #f0f3f2;
  border: 1px solid #dfe5e8;
  margin-right: -1px;
}
.pricing-table-style-1 .pricing-cost {
  font-size: 45px;
  color: #ffffff;
  line-height: 1;
}
.pricing-table-style-1 .time {
  color: #ffffff;
}
.pricing-column .wrap-style {
  display: none;
}
.pricing-table-style-1 .pricing-column-wrapper .pricing-price {
  margin-right: -1px;
  margin-left: -1px;
  width: 101%;
}
.pricing-table-style-1 .pricing-column .pricing-row:last-of-type {
  border-bottom: none;
}
.pricing-table-style-1 .pricing-footer{
  padding-bottom: 26px;
}
.pricing-table-style-1 .highlighted .pricing-price-title-wrapper {
  padding-bottom: 28px;
}
.pricing-table-style-8 .pricing-price,
.pricing-table-style-7 .pricing-price,
.pricing-table-style-4 .pricing-price-row,
.pricing-table-style-3 .pricing-price-row,
.pricing-table-style-2 .pricing-price,
.pricing-table-style-1 .pricing-price-row {
  background-color: transparent !important;
  background-image: none !important;
}
.pricing-table-style-5 .pricing-price {
  background-image: none !important;
  background-color: #ffffff !important;
}

.pricing-column-wrapper {
  min-width: 200px;
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 20px;
  line-height: 25px;
  &.highlighted {
    padding-bottom: 28px;
    margin-top: 0;

    .pricing-column {
      background-color: #ffffff;
    }
  }
}

.inline-column {
  float: none;
  display: inline-block;
  text-align: left;
  vertical-align: top;
  margin-bottom: 42px;
}

.pricing-price-title-wrapper {
  padding-top: 33px;
  padding-bottom: 33px;
  line-height: 25px;
  font-size: 16px;
  color: #99a9b5;
  text-align: center;

  .pricing-price-title {
    color: #a6a6a6;
    font-size: 24px;
    line-height: 38px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}

.pricing-column {
  background-color: #f0f3f2;
  border: 1px solid #dfe5e8;
  margin-right: -1px;
}
.pricing-price-wrapper {
  text-align: center;
}
.pricing-column-top-choice {
  background-color: #393d50;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -25px;
  left: 38px;
  border-radius: 50%;
  z-index: 100;
  .pricing-column-top-choice-text {
    color: #ffffff;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
  }
}


.login-window {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  z-index: 5000;
}

.help-block.error, .val-error, small.error {
  color: #dd4b39;
}

small.warnings {
  color: #f39c12;
}

.bold {
  font-weight: bold !important;
}

.datepicker {
  z-index: 1151 !important;
  cursor: pointer;
}

.image-remove-icon {
  position: absolute;
  top: 0;
  right: 0;
  @media (max-width: @screen-xs-max) {
    right: 15px;
  }
}

.row-paddings-compensation {
  padding: 0 10px;

  @media (max-width: @screen-xs-max) {
    padding: 0;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.icheck-group {
  //padding-top: 5px;
}
.icheck-label {
  position: relative;
  top: 1px;
  padding-left: 3px;
  cursor: pointer;
}

.box-header.fa-plus::before, .box-header.fa-minus::before {
  content: '';
}

.nobreak {
  white-space: nowrap;
}


.buck-avatar-border {
  border-color: #00a7d0 !important;
}

.doe-avatar-border {
  border-color: #ca195a !important;
}


.info-box.active {
  -webkit-box-shadow: 0 2px 7px 0 rgba(0,0,0,0.8);
  box-shadow: 0 2px 7px 0 rgba(0,0,0,0.8);
}


.bg-gray-active img {
  border-color: #fff !important;
}

.loader {
  margin-top: 10px;
  margin-bottom: 40px;
}

.loader1 {
  margin-top: 10px;
  margin-bottom: 10px;
}

#litter-weigh-modal .info-box-icon img,
#global-litter-weigh-modal .info-box-icon img {
  max-width: 80%;
  margin:10px auto;
  border: 3px solid;
}
#litter-butcher-modal .table td img,
#global-litter-butcher-modal .table td img {
  max-width: 45px;
  border: 3px solid;
}

#breeder-butcher-modal .table td img {
  max-width: 40px;
  border: 2px solid;
}

.kit-butchered-icon,
.kit-breeder-icon,
.kit-foster-icon {
  position: absolute;
  bottom: 16px;
  right: 20px;
  font-size: 20px;
}

.kit-butchered-icon {
  color: #dd4b39;
}
.kit-breeder-icon {
  color: #00a65a;
}

.kit-actions-dropdown {
  position: absolute;
  top: 4px;
  right: 20px;
}

.btn-group-sm.kit-actions-dropdown > .btn {
  padding: 3px 8px;
}

.butched {
  opacity: .5;
}

.box-statistics {
  position: absolute;
  left: 200px;
  top: 6px;
  line-height: 1.4;
}

.chart-legend{
  ul{
    list-style: none;
    padding-left: 0;
  }
  li span{
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 5px;
  }
}

.expand-transition {
  transition: all .4s ease;
  max-height: 2000px;
  padding: 10px;
  overflow: hidden;
}

.expand-enter, .expand-leave {
  max-height: 0;
  padding: 0 10px;
  opacity: 0;
}

body.modal-open {
  overflow: visible;
}

.modal {
  overflow-y: auto !important;
}

.profile-img-container {
  position: relative;
  overflow: hidden; /* added */
}
.profile-img-container:hover {
  cursor: pointer;
}

.box-breeder-profile .profile-img-container:hover,
.profile-img-container > img:hover {
  opacity: 0.5
}
.profile-img-container:hover a, .profile-img-container.loading a{
  opacity: 1; /* added */
  top: 0; /* added */
  z-index: 500;
}
/* added */
.profile-img-container:hover a span, .profile-img-container.loading a span {
  top: 50%;
  position: absolute;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}
/* added */
.profile-img-container a {
  display: block;
  position: absolute;
  top: -100%;
  opacity: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  color: inherit;
}

/* added - wut? */
.fa.notification-read {
  padding: 5px;
  cursor: pointer;
  font-size: 10px;
  float: right;
  margin: 10px 10px 0 0;
}

.box-profile__properties{
  div{
    border-right: 1px solid #f4f4f4;
    height:70px;
  }
  div:nth-of-type(3n){
    border-right: none;
    //&:after{
    //  content: '';
    //  display: block;
    //  padding-bottom: 20px;
    //}
  }
}

.introjs-helperLayer {
  background-color: rgba(255,255,255,.5);
}
.image-cropper-modal {
  .image-cropper-container {
    max-width: 100%;
    max-height: 400px;
  }
}
.image-upload-cropper-modal {
  .modal-dialog {
    margin-top: 0px;
  }
}

.col-min-height {
   .col-xs-2, .col-xs-4, .col-xs-6, .col-xs-8, .col-xs-12 {
    min-height: 34px;
  }
}

.breed-profile-action {
  li,
  i.fa {
    color: #000;
  }
}
.open + .dropdown-menu {
  display: block !important;
}

.hole-select {
  padding-top: 7px;
}

.predict-btn{
    float: right !important;
    margin-right: 16px !important;
    border-color: #ffffff !important;
}
.current-plan {
  opacity: 0.5;
  cursor: default;
}

.modal-success {
  .bottom-link-close-modal {
    margin-right: 20px;
    display: inline-flex;
    text-decoration: underline;
    color: #fff;
    &:hover {
      text-decoration: none;
    }
  }
}