/*
 * Page: Profile
 * -------------
 */

.profile-user-img {
  margin: 0 auto;
  width: 100px;
  padding: 3px;
  border: 3px solid @gray;
}
.profile-user-img-list-item {
  margin: 0 auto;
  width: 60px;
  padding: 3px;
  border: 3px solid @gray-lighter;
}
.profile-user-img-list-item:hover,
.profile-user-img-list-item.active {
  border: 3px solid @gray;
}

.breeder-user-img {
  width: 100%;
  padding: 1px;
  float: right;
}
.single-breeder-user-img {
  margin: 0 auto;
  max-height: 150px;
  max-width: 150px;
  float: none;
}
.breeder-user-img-list-item {
  margin: 0 auto;
  width: 50%;
  padding: 1px;
  float: left;
}
.breeder-username {
  font-size: 21px;
  margin-top: 5px;
}

.post {
  border-bottom: 1px solid @gray;
  margin-bottom: 15px;
  padding-bottom: 15px;
  color: #666;
  &:last-of-type {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .user-block {
    margin-bottom: 15px;
  }
}
