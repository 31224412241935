.main-header.rebrand {
  max-height: 150px;
}

.rebrand {
  .login-logo {
    img {
      object-fit: contain;
      width: 85%;
    }

    .former-title {
      font-size: 25px;
      letter-spacing: 1px;
    }
  }

  .logo {
    img:not(.logo-only) {
      object-fit: contain;
      width: 90%;
      height: 100%;
    }

    .logo-only {
      display: none;
    }
  }

  .btn-social.btn-lg > :first-child {
    font-size: 1.3em;
  }
  .btn-social > :first-child {
    font-size: 1.2em;
  }

  .navbar-custom-menu {
    .nav {
      li.dropdown {
        ul.dropdown-menu {
          top: 50px;
          right: 28px;
          color: rgba(0, 0, 0, 0.54);
        }
      }
    }


    .settings-dropdown {
      padding: 11px;

      .fa {
        font-size: 36px;
      }
    }
  }
}

@media (min-width: 768px) {
  .sidebar-mini.sidebar-collapse .main-header.rebrand .logo {
    .logo-only {
      display: revert;
    }
    .logo-with-text {
      display: none;
    }
  }

  .navbar-custom-menu {
    padding-right: 10px;
  }
}
