.main-header.rebrand {
  max-height: 150px;
}
.rebrand .login-logo img {
  object-fit: contain;
  width: 85%;
}
.rebrand .login-logo .former-title {
  font-size: 25px;
  letter-spacing: 1px;
}
.rebrand .logo img:not(.logo-only) {
  object-fit: contain;
  width: 90%;
  height: 100%;
}
.rebrand .logo .logo-only {
  display: none;
}
.rebrand .btn-social.btn-lg > :first-child {
  font-size: 1.3em;
}
.rebrand .btn-social > :first-child {
  font-size: 1.2em;
}
.rebrand .navbar-custom-menu .nav li.dropdown ul.dropdown-menu {
  top: 50px;
  right: 28px;
  color: rgba(0, 0, 0, 0.54);
}
.rebrand .navbar-custom-menu .settings-dropdown {
  padding: 11px;
}
.rebrand .navbar-custom-menu .settings-dropdown .fa {
  font-size: 36px;
}
@media (min-width: 768px) {
  .sidebar-mini.sidebar-collapse .main-header.rebrand .logo .logo-only {
    display: revert;
  }
  .sidebar-mini.sidebar-collapse .main-header.rebrand .logo .logo-with-text {
    display: none;
  }
  .navbar-custom-menu {
    padding-right: 10px;
  }
}
