.calendar-v2 .fc-basic-view td.fc-day-number,
.calendar-v2 .fc-basic-view td.fc-week-number span {
  padding-top: 10px;
  padding-right: 10px;
  font-size: 12px;
}
.calendar-v2 .fc-day-grid-event > .fc-content {
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.calendar-v2 .fc-event {
  cursor: pointer;
  margin-left: 15px;
  margin-right: 15px;
  border: none;
  border-radius: 0px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.calendar-v2 .fc-event.breeder-event {
  background-color: rgba(0, 115, 183, 0.1);
  border-left: 8px solid #0073b7;
  color: #0073b7;
}
.calendar-v2 .fc-event.litter-event {
  background-color: rgba(0, 166, 90, 0.1);
  border-left: 8px solid #00a65a;
  color: #00a65a;
}
.calendar-v2 .fc-event.general-event {
  background-color: rgba(128, 128, 128, 0.1);
  border-left: 8px solid #808080;
  color: #808080;
}
.calendar-v2 .fc-widget-header {
  color: var(--primary-white);
  background: var(--secondary-green);
  padding: 5px 0;
  border: none;
}
.calendar-v2 .fc-center > div:first-child {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.calendar-v2 .fc-center > div:first-child h2 {
  font-size: 26px;
  font-weight: 600;
  margin: 0 8px;
}
.calendar-v2 .fc-center > div:first-child button {
  font-size: 20px;
  background: none;
  border: none;
  box-shadow: none;
}
.calendar-v2 .fc-center > div:first-child button span {
  font-size: 70px;
}
.calendar-v2 .fc-center > div:first-child button:hover {
  border-radius: 8px;
  background: rgba(128, 128, 128, 0.1);
}
@media (max-width: 780px) {
  .calendar-v2 .fc-event.breeder-event,
  .calendar-v2 .fc-event.litter-event,
  .calendar-v2 .fc-event.general-event {
    border: 0;
    margin: 0;
  }
  .fc-more,
  .a {
    display: block;
    text-align: center;
    font-size: 0.80em;
  }
  .home-schedule-space {
    padding: 0px 0px;
  }
  .rabbit-text-wrap {
    font-size: 10px !important;
    font-weight: bold !important;
    line-height: 10px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
