/* GLOBAL UPDATE FOR OUTLINED BUTTON */
.btn-outline {
  background-color: #fff;
  color: #444;
  border-color: #ddd;
  &:hover,
  &:active,
  &.hover {
    background-color: darken(#f4f4f4, 5%);
    color: #444;
  }
}
.btn-default {
  background-color: #f4f4f4;
  color: #444;
  border-color: #ddd;
  &:hover,
  &:active,
  &.hover {
    background-color: darken(#f4f4f4, 5%);
    color: #444;
  }
}

.aligned-row {
  display: flex;
  flex-flow: row wrap;

  &::before {
    display: block;
  }

  .pull-right {
    margin-left: auto;
  }
}

.alert-v2 {
  .modal-dialog {
    max-width: 500px;
  }
  .modal-title {
    display: flex;
    align-items: center;

    .fa {
      font-size: 25px;
      margin-right: 10px;
    }
  }
  .modal-body {
    min-height: 175px;
    font-size: 17px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.h-100 {
  height: 100%;
}

.mx-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.color-warning {
  color: var(--warning);
}
.color-success {
  color: var(--primary-green);
}
.color-danger {
  color: var(--danger);
}

.bg-primary-v2 {
  background-color: var(--primary-green);
}
.bg-primary-v2-i {
  background-color: var(--primary-green) !important;
}
.bg-secondary-v2 {
  background-color: var(--secondary-grey);
}
.bg-info-v2 {
  background-color: var(--primary);
}
.bg-warning-v2 {
  background-color: var(--primary-yellow);
}
.bg-danger-v2 {
  background-color: var(--danger);
}
.bg-gunmetal {
  background-color: var(--gunmetal);
}
.bg-dark-grey {
  background-color: var(--dark-grey);
}
.bg-faded-grey {
  background-color: var(--faded-grey);
}
.mt-30 {
  margin-top: 30px;
}
.mt-50 {
  margin-top: 50px;
}
.my-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.mid-checkbox input[type="checkbox"] {
  width: 18px;
  height: 18px;
  margin-top: 0px;
}

.mid-checkbox input[type="radio"] {
  transform: scale(1.5);
  margin-top: 0px;
}

.pt-10-i {
  padding-top: 10px !important;
}

.ml-5 {
  margin-left: 5px;
}

.ml-0-i {
  margin-left: 0 !important;
}

.ml-20-i {
  margin-left: 20px !important;
}

.select2-results__options {
  li {
    display: flex !important;
  }
}
.select2-selection__rendered {
  display: flex !important;
  align-items: center !important;
}

.flag-icon-squared.img-circle {
  height: 25px;
  width: 25px;
}

.option-title {
  line-height: 25px;
}

.input-group.task-icons {
  .select2-container--default .select2-selection--single,
  .select2-selection .select2-selection--single {
    padding: 8px 12px 6px 12px;
  }
}

.capitalize {
  text-transform: capitalize;
}

.admin-reports-v2 {
  .small-box {
    min-height: 102px;

    .over-limit-warning {
      position: absolute;
      top: -10px;
      right: -10px;

      .fa {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        line-height: 30px;
        background-color: var(--danger);
        text-align: center;
        color: var(--primary-white);
      }
    }
  }
}

.pointer-none {
  pointer-events: none;
}

.profile-tabs {
  overflow: auto;
}

.settings-image {
  width: 50px;
  text-align: center;
}

.display-none {
  display: none;
}

.justify-center {
  justify-content: center;
}

.settings-image > .cloudinary_fileupload {
  display: none;
}

.weigh-options .netliva-switch {
  margin: 10px;
}

.rabbit-type-options .netliva-switch {
  margin: 10px;

  label {
    margin-bottom: 0;
  }
}

.px-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.py-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.w-50 {
  width: 50%;
}

.w-80 {
  width: 80%;
}

.btn-undo {
  margin-left: auto;
  margin-right: 30px;
  font-weight: bold;
  background-color: var(--border-light-grey);
}

.cursor-pointer-only {
  cursor: pointer;
}

.border-olive {
  border-color: var(--secondary-green) !important;
}

.border-gray-active {
  border-color: #b5bbc8 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.mt-15 {
  margin-top: 15px;
}

.ml-50 {
  margin-left: 50px;
}

.mr-5 {
  margin-right: 5px;
}

.label-lg {
  font-size: 16px;
  font-weight: bold;
}

.label-xl {
  font-size: 20px;
  font-weight: bold;
}

.mh-100 {
  min-height: 100px;
}

.pl-0 {
  padding-left: 0;
}

.action-icon {
  background: none !important;
  color: #000 !important;
}

.text-underline {
  text-decoration: underline;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-150 {
  padding-bottom: 150px;
}

.bl-primary {
  border-left: 7px solid var(--primary-green);
}

.ml-n-20 {
  margin-left: -20px;
}

ul.breed-profile-action {
  right: 0;
  left: auto;
}

table.dataTable thead .sorting:before {
  display: inline-grid;
  position: revert;
  margin-left: 5px;
}

.d-none {
  display: none;
}

.tab-error {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  color: var(--danger);
}

.nav-pills > li > .modal-nav.text-danger,
.nav-pills > li.active > .modal-nav.text-danger {
  color: var(--danger) !important;
}

.breeders-v2.dashboard-v2 .global-forms-container {
  .global-forms-btn {
    border-radius: 50%;
    height: 45px;
    width: 45px;
    margin: 0;
    padding: 0;
    padding-left: 11px;
    padding-right: 11px;
    background-color: var(--border-light-grey);
  }

  .global-forms {
    right: 0;
    left: auto;
    padding: 40px 20px;
    min-width: 350px;
    border-radius: 8px !important;
    box-shadow: 0px 0px 8px #888;

    .main-title {
      font-size: 20px;
      font-weight: 500;
    }

    .action {
      flex-direction: column;
      cursor: pointer;

      .icon {
        display: flex;
        align-items: center;
        font-size: 25px;
        color: var(--primary-white);
        background-color: var(--primary-green);
        justify-content: center;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        padding: 0;
        margin-bottom: 10px;
      }

      .title {
        color: #3d3d3d;
        font-size: 14px;
        text-align: center;
      }
    }

    .action:hover {
      .icon {
        box-shadow: 0px 0px 5px #888;
      }

      .title {
        font-weight: bold;
      }
    }
  }
}

.jq-toast-single a:hover {
  color: var(--primary-white);
}

.blog-post {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 10px;

  .header {
    img {
      object-fit: contain;
      width: 100%;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }
  }

  .body {
    padding: 20px;

    .title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 10px;

      a {
        color: #333333;
      }
      a:hover {
        text-decoration: underline;
      }
    }

    .description {
      font-size: 14px;
      margin-bottom: 10px;
    }

    .link {
      a {
        color: var(--primary-green);
      }
      a:hover {
        text-decoration: underline;
      }
    }
  }
}

#everbreed-blog-btn {
  border-radius: 100px;
  padding: 8px 40px;
  font-weight: 500;
}

.card.form-control {
  border-radius: 4px;
}

.justify-center {
  justify-content: center;
}

.notes-type-btn {
  .btn {
    font-weight: bold;
    padding: 6px 20px;
    border-radius: 8px;
  }

  .btn.active {
    background: #465156;
    color: var(--primary-white);
  }
}

.text-primary-green {
  color: var(--primary-green);
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.close-message {
  position: absolute;
  right: 30px;
  top: 38px;

  .fa {
    font-size: 24px;
    opacity: 0.6;
    cursor: pointer;
  }

  .fa:hover {
    opacity: 1;
  }
}

.overflow-auto {
  overflow: auto;
}

.pt-40 {
  padding-top: 40px;
}

.mb-0-i {
  margin-bottom: 0 !important;
}

.table-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}

.ml-auto {
  margin-left: auto;
}

.select2-selection__clear {
  margin-right: 5px;
}

.d-block-i {
  display: block !important;
}

.font-bold {
  font-weight: bold;
}

.flex-column {
  flex-direction: column;
}

.table-filters {
  .title {
    font-style: italic;
    font-weight: 100;
    font-size: 20px;
    margin-right: 20px;
  }

  .actions {
    .btn {
      border: none;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      font-size: 18px;
    }
  }
}

.faux-bullet {
  margin-right: 10px;
  font-size: 10px;
}

.delete-account-btn {
  padding: 6px 50px;
  font-weight: 600;
  font-size: 14px !important;
}

.f-18 {
  font-size: 18px;
}

.f-20 {
  font-size: 20px;
}

.mh-500 {
  min-height: 500px;
}

.rating {
  font-size: 45px;
  margin-top: 20px;

  .star {
    cursor: pointer;
  }
}

.feedback-summary {
  justify-content: end;

  .feedback-content {
    margin: 0 10px;

    .title {
      padding: 0 10px;
    }

    .description {
      font-weight: bold;
    }
  }
}

.breeding-does {
  max-height: 100px;
  padding: 5px 10px;
  border: 1px solid var(--border-light-grey);
  border-radius: 5px;
  overflow-y: auto;

  .fa-circle {
    font-size: 12px;
    margin-right: 10px;
    color: var(--primary-green);
  }
}

.opacity-5 {
  opacity: 50%;
}

.dropdown.tasks-menu.tasks-menu-v2 .farm-dropdown {
  width: 250px !important;
  padding: 10px 20px !important;
  color: var(--gunmetal) !important;

  .btn.btn-success {
    border-radius: 100px;
    font-size: 12px;
    padding: 6px 20px;
  }
}

input[type="radio"],
input[type="checkbox"] {
  accent-color: var(--primary-green);
}

.input-verify {
  float: right;
  margin-right: 9px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
  font-size: 15px !important;
  width: 25px !important;
  height: 25px !important;
  color: var(--primary-white);
  background-color: var(--secondary-green) !important;
}

.input-verify.text {
  width: revert !important;
  font-weight: 500 !important;
  border-radius: 100px !important;
  padding: 0 10px !important;
  font-size: 12px !important;
}

.text-strikethrough {
  text-decoration: line-through;
}

.pricing-duration-label {
  background: var(--warning);
  padding: 5px;
  border-radius: 100px;
  font-size: 14px;
  font-weight: bold;
}

.plan-duration-label {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
}

.flip-horizontal {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.font-16 {
  font-size: 16px;
}

.font-17-i {
  font-size: 17px !important;
}

.font-15 {
  font-size: 15px;
}

.text-regular {
  font-weight: 400;
}

.mw-250 {
  max-width: 250px !important;
}

.min-height-600 {
  min-height: 600px !important;
}

.profile-photo {
  width: 36px !important;
  height: 36px !important;
  border: none !important;
}

.profile-name {
  font-size: 13px;
  line-height: 13px;
  padding: 5px;
}

.d-inline-flex {
  display: inline-flex;
}

.d-block {
  display: block;
}

.help-label {
  margin-top: 15px !important;
  padding: 20px !important;
  background-color: var(--primary-white) !important;

  .title {
    img {
      margin-right: 5px !important;
    }
  }

  .description {
    font-size: 18px !important;
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.wizard-btn {
  border-radius: 100px;
  font-size: 16px;
  font-weight: 500;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
}

.b-radius-8 {
  border-radius: 8px !important;
}

.bh-radius-8 {
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
}

.b-none-i {
  border: none !important;
}

.box-shadow {
  box-shadow: 0px 0px 8px #888;
}

.page-warning {
  border-radius: 6px;
  background-color: #f4f4f4;
  padding: 20px;
}

.text-left-i {
  text-align: left !important;
}

.pedigree-next-button {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}

@media (max-width: 425px) {
  .profile-name {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .limit-5 {
    width: 20% !important;
  }
}

@media (min-width: 768px) {
  .ml-md-50 {
    margin-left: 50px;
  }

  .ml-md-20 {
    margin-left: 20px;
  }

  .pl-60-md {
    padding-left: 60px;
  }
}

@media (max-width: 768px) {
  .mb-sm-10 {
    margin-bottom: 10px;
  }

  .flex-column-md {
    flex-direction: column;
  }
}

@media (min-width: 1780px) {
  .row > .col-xl-1 {
    width: 7.5%;
  }
}
